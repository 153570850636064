import React, { useState, useEffect, useRef } from "react";
import AppBarQA from "./AppBarQA";
import useSound from 'use-sound';


import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import { ToastContainer, toast } from "react-toastify";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CopyAllTwoToneIcon from "@mui/icons-material/CopyAllTwoTone";
import DoorBackTwoToneIcon from "@mui/icons-material/DoorBackTwoTone";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import OutlinedInput from '@mui/material/OutlinedInput';


const Home = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code_room = searchParams.get("code");
  const token_qa = localStorage.getItem("token_qa");
  const GETcode = localStorage.getItem("code");
  const room_ref = localStorage.getItem("room_ref");
  const [backdrop, setBackdrop] = React.useState(false);


  const navigate = useNavigate();

  const [code, setCode] = useState("" || code_room || GETcode);
  const [acc_username, setUsername] = useState("");
  const [data, setDataRoom] = useState("");

  const [status, setStatus] = useState(true);
  const [text, setText] = useState("");

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  useEffect(() => {
    const setVolume = () => {
      if (audioRef.current) {
        audioRef.current.volume = 0.5; // ลดระดับเสียงลง 50%
      }
    };

    setVolume();
  }, [isPlaying]);


  useEffect(() => {
    if (code) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await fetch(`https://localhost/api/q/a/get`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ code }), // Pass an object with code and acc_username properties
          });

          const data = await response.json();

          if (data.status === 200) {
            setIsPlaying(true);
            navigate(`/app/QA/home?code=${code}`);
            localStorage.setItem("DataRoom", JSON.stringify(data.dataRoom));
            setDataRoom(data.dataRoom); // Assuming data.dataRoom contains the room data
          } else {
            navigate(`/app/QA/home`);
            toast.error(data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setDataRoom("");
            setCode("");
          }

          setLoading(false); // Set loading to false after fetching data
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }
  }, [code]); // Depend on code to trigger the effect when it changes

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!acc_username) {
      toast.warn("กรุณากรอกชื่อผู้ใช้", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setStatus(false);
      setText("กรุณากรอกชื่อผู้ใช้");
      setTimeout(() => {
        setStatus(true);
        setText("");
      }, 3000);

      return;
    }

    if (!code) {
      toast.warn("กรุณากรอกโค้ดเข้าห้อง", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setStatus(false);
      setText("กรุณากรอกโค้ดเข้าห้อง");
      setTimeout(() => {
        setStatus(true);
        setText("");
      }, 3000);

      return;
    }

    try {
      setLoading(true);
      const requestData = {
        code,
        acc_username,
      };
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/q/a/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      setLoading(false);

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token_qa", data.token_qa);
        localStorage.setItem("room_ref", code);
        localStorage.setItem("code", code);
        localStorage.setItem("TokenAuth", data.TokenAuth);
        toast.success(data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate(`/app/QA/play?code=${code}`);
      } else {
        localStorage.setItem("code", code);
        toast.error(data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setCode("");
        setStatus(false);
        setText(data.message);
        setTimeout(() => {
          setStatus(true);
          setText("");
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      setCode("");
      localStorage.removeItem('code');
      alert("เกิดข้อผิดพลาดระหว่างการเข้าสู่ห้อง:", error);
    }
  };

  const handlePaste = async () => {
    try {
      setLoading(true);
      const clipboardText = await navigator.clipboard.readText();
      setLoading(false);
      setCode(clipboardText);
    } catch (error) {
      console.error("Error pasting from clipboard:", error);
    }
  };

  const handleOutRoom = async () => {
    try {
      setLoading(true);
      setTimeout(() => {
        navigate(`/app/QA/home`);
        setLoading(false);
        setCode("");
        setDataRoom("");
        localStorage.removeItem('code');
        toast.success(`ออกห้อง ${data.name_room || "Q&A"} สำเร็จ`, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      }, 2000);
     
    } catch (error) {
      console.error("Error pasting from clipboard:", error);
    }
  };

  useEffect(() => {

    if(token_qa){
        setBackdrop(true);
        toast.error("ยังมีห้องที่เข้าค้างไว้อยู่");
        setTimeout(() => {
            // window.location.href = `/app/QA/play?code=${room_ref}`;
            navigate(`/app/QA/play?code=${room_ref}`);
        }, 4000);
    }

    },[])
  

  return (
    <>
      <AppBarQA />
      <ToastContainer />
      <style>
        {`
        body {
            background-image: url("${data.img_room}");
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: center;
            background-size: cover;
            overflow-x: hidden;
            backdrop-filter: blur(5px); /* ปรับการใช้ backdrop-filter: "blur(5px)"; เป็น backdrop-filter: blur(5px); */
            border-radius: 1vh;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* เพิ่ม box-shadow ที่นี่ */
          }
        `}
      </style>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            paddingTop: "35%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: data.color_room || "#a525f5" }}>
            <LockTwoToneIcon />
          </Avatar>
          <h1 style={{ color: data.color_room || "" }}>
            เข้าสู่ห้อง : {data.name_room || "Q&A"}
          </h1>
          <h4 style={{ color: data.color_room || "" }}>{`รายละเอียด : ${
            data.details_room || ""
          }`}</h4>
          <Box
            component="form"
            onSubmit={handleSubmit}
            width={"50vh"}
            noValidate
            sx={{ mt: 1 }}
          >
            {status ? (
              <>
                {!code ? (
                  <>

                    <OutlinedInput
                      aria-readonly
                      className="content"
                      type="text"
                      margin="normal"
                      required
                      fullWidth
                      placeholder="MTWIT-ROOM-XXXX-XXXX-XXXX"
                      id="code"
                      // label="รหัสห้อง"
                      name="code"
                      autoComplete="code"
                      autoFocus
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      helperText={"กรุณาคลิกวางรหัสห้องเท่านั้น"}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        style: { color: "#a525f5" },
                      }}
                      sx={{
                        "& input": {
                          color: "#a525f5",
                        },
                      }}
                      // id="outlined-adornment-weight"
                      endAdornment={<InputAdornment position="end"
                      onClick={handlePaste}><CopyAllTwoToneIcon /></InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />

                    <TextField
                      className="content"
                      type="text"
                      margin="normal"
                      required
                      fullWidth
                      placeholder="XXXXX"
                      id="code"
                      label="รหัสประจำตัวนักเรียน"
                      name="code"
                      autoComplete="code"
                      autoFocus
                      value={acc_username}
                      onChange={(e) => setUsername(e.target.value)}
                      helperText={"กรุณากรอกรหัสนักเรียน"}
                      InputLabelProps={{
                        style: { color: "#a525f5" },
                      }}
                      sx={{
                        "& input": {
                          color: "#a525f5",
                        },
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <LockTwoToneIcon />
                        </InputAdornment>
                      }
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        color: "#fff",
                        backgroundColor: "#8700ef",
                        "&:hover": {
                          backgroundColor: "#4c0185",
                        },
                      }}
                      startIcon={<LockOpenTwoToneIcon />}
                    >
                      {loading ? (
                        <CircularProgress sx={{ color: "#fff" }} size={24} />
                      ) : (
                        "เข้าสู่ห้อง Q&A"
                      )}
                    </Button>
                  </>
                ) : (
                  <>
                    <TextField
                      className="content"
                      type="text"
                      margin="normal"
                      required
                      fullWidth
                      placeholder="XXXXX"
                      id="code"
                      label="รหัสประจำตัวนักเรียน"
                      name="code"
                      autoComplete="code"
                      autoFocus
                      value={acc_username}
                      onChange={(e) => setUsername(e.target.value)}
                      helperText={`รหัสเข้าห้อง ${code}`}
                      InputLabelProps={{
                        style: { color: data.color_room || "#a525f5" },
                      }}

                      sx={{
                        "& input": {
                          color: data.color_room || "#a525f5",
                        },
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <LockTwoToneIcon />
                        </InputAdornment>
                      }
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        color: "#fff",
                        backgroundColor: data.color_room || "#8700ef",
                        "&:hover": {
                          backgroundColor: data.color_room || "#4c0185",
                        },
                      }}
                      startIcon={<LockOpenTwoToneIcon />}
                    >
                      {loading ? (
                        <CircularProgress sx={{ color: "#fff" }} size={24} />
                      ) : (
                        `เข้าสู่ห้อง : ${data.name_room || "Q&A"}`
                      )}
                    </Button>
                  </>
                )}

                {code ? (
                  <Button
                    fullWidth
                    variant="contained"
                    disableElevation
                    sx={{
                      mt: 1,
                      mb: 2,
                      color: "#fff",
                      backgroundColor: "#8700ef",
                      "&:hover": {
                        backgroundColor: "#4c0185",
                      },
                    }}
                    startIcon={<DoorBackTwoToneIcon />}
                    onClick={handleOutRoom}
                  >
                    {loading ? (
                      <CircularProgress sx={{ color: "#fff" }} size={24} />
                    ) : (
                      `ออกจากห้อง : ${data.name_room || "Q&A"}`
                    )}
                  </Button>
                ) : (
                  <></>
                )}

                <h4 style={{ color: data.color_room || "" }}>
                  การใช้ Q&A หรือ ระบบทำข้อสอบออนไลน์ ของ โรงเรียนแม่ตื่นวิทยาคม
                </h4>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>คำเตือน</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      - เปลี่ยนแท็บ เท่ากับคะแนนเป็น 0 <br />
                      - ทำการพับจอ เท่ากับคะแนนเป็น 0 <br />
                      - เวลาหมดเท่ากับคะแนนเป็น 0 <br />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>การจำกัดเวลา</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      ข้อสอบ 1 ข้อจะทำกี่นาทีก็ได้แต่ต้องอยู่เวลาในกรอบทั้งหมด
                      และเมื่อเวลาหมดแล้วยังไม่กดส่งข้อสอบทุกข้ิอจะเท่ากับ 0
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel2a-header"
                  >
                    <Typography>การเกิดข้อผิดพลาด</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      หากเกิดข้อผิดพลาดจากระบบสามารถแจ้งคุณครูที่ส่งลิงค์แบบทดสอบได้เลยเพราะทางเราจะมีการเก็บ
                      Log การใช้งานว่ามีการ Error ตรงไหน
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </>
            ) : (
              <>
              <Box sx={{ paddingTop: "8%", }}>
                <Alert severity="error">
                  <AlertTitle>แจ้งเตือน</AlertTitle>
                  {text} ❗️❗️ โปรดรอ 3 วิ
                </Alert>  
              </Box>
              </>
            )}
          </Box>
        </Box>
        
        <Button
        fullWidth
        variant="contained"
        disableElevation
        sx={{
          mt: 3,
          mb: 2,
          color: "#fff",
          backgroundColor: "#8700ef",
          "&:hover": {
            backgroundColor: "#4c0185",
          },
        }}
         onClick={togglePlay}>
        {isPlaying ? 'หยุดเพลงระหว่างรอ' : 'เล่นเพลงระหว่างรอ'}
      </Button>
      <audio
        ref={audioRef}
        src="/sounds/Home-KAHOOT.mp3"
        onEnded={() => setIsPlaying(false)}
      />
        {code ? (
          <div className="QR">
            <img
              src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=http://localhost:3000/app/QA/home?code=${code}&choe=UTF-8`}
              width={120}
              title={`เข้าสู่ห้อง: ${data.name_room || "Q&A"}`}
            />{" "}
            <br />
            เชิญเพื่อนด้วยการ
            <br />
            สแกน QR CODE
          </div>
        ) : (
          <></>
        )}
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
       <LinearProgress sx={{ color: "#fff" }} />
            <h3>เข้าห้อง {data.name_room || "Q&A"} อีกครั้ง</h3>
      </Backdrop>
    </>
  );
};

export default Home;
