import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Smartcard = () => {
  const [name, setName] = useState('');
  const [userLineID, setUserLineID] = useState('');
  const [pictureUrl, setPictureUrl] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [backdrop, setBackdrop] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const callbackUrl = queryParams.get('callbackUrl');

  const initializeLiff = async () => {
    try {
      setBackdrop(true); // Show the loading indicator
      let liffId = '';
      if (callbackUrl === '/app/line/profile') {
        liffId = '2000013406-yqjB4aqE';
      } else if (callbackUrl === '/QrCheckPay') {
        liffId = '2000013406-9Ly3rDLe';
      } else {
        alert("ไม่สามารถ Login Line OA ได้");
        navigate("/app/");
      }

      await window.liff.init({ liffId });

      const getProfile = await window.liff.getProfile();
      console.log('LINE Profile:', getProfile);

      // Update state with profile information
      setName(getProfile.displayName);
      setUserLineID(getProfile.userId);
      setPictureUrl(getProfile.pictureUrl);
      setStatusMessage(getProfile.statusMessage);

      // Send userId to your API
      await sendUserIdToAPI(getProfile.userId);
    } catch (error) {
      console.error('Error initializing LIFF or getting profile:', error);
    } finally {
      setBackdrop(false); // Hide the loading indicator, regardless of success or failure
    }
  };

  useEffect(() => {
    initializeLiff();
  }, []);

  const sendUserIdToAPI = async (userId) => {
    try {
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your API
      const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/login-line`;

      // Example of sending userId to API using fetch
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      const data = await response.json();
      localStorage.setItem('token', data.accessToken);

      if (callbackUrl) {
        navigate(callbackUrl);
      }
    } catch (error) {
      console.error('Error sending userId to API:', error);
    }
  };

  return (
    <>
      <header className="App-header">
        <div className="support">
          {(pictureUrl && pictureUrl !== '') && <img width="25%" src={pictureUrl} alt="Profile" />}
        </div>
        {(name && name !== '') && <p>Name: {name}</p>}
        {(userLineID && userLineID !== '') && <p>LineID: {userLineID}</p>}
        {(statusMessage && statusMessage !== '') && <p>Status Message: {statusMessage}</p>}
      </header>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
        <p>กำลังเข้าสู่ระบบ...</p>
      </Backdrop>
    </>
  );
};

export default Smartcard;
