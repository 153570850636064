import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Backdrop,
  Grid,
  Paper,
  Stack,
  CircularProgress,
  CssBaseline,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AppBarQA from "./AppBarQA";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useSound from 'use-sound';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


const Play = () => {
  const [data, setDataRoom] = useState({});
  const [loading, setLoading] = useState(false);
  const room_ref = localStorage.getItem("room_ref");
  const token_qa = localStorage.getItem("token_qa");
  const DataRoom_ = localStorage.getItem("DataRoom");
  const TokenAuth = localStorage.getItem("TokenAuth");
  const navigate = useNavigate();
  const [dataQuestion, setdataQuestion] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [remainingTime, setRemainingTime] = useState(null);
  const [status, setStatus] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [answers, setAnswers] = useState(true);
  const [answersText, setAnswersText] = useState([]);

  const DataRoom = JSON.parse(DataRoom_);

  if(!token_qa || !room_ref || !dataQuestion){
    toast.error('กรุณาเข้าห้องใหม่');
    localStorage.removeItem('token_qa');
    localStorage.removeItem('room_ref');
    localStorage.removeItem('DataRoom');
    localStorage.removeItem('time_remaining');
    navigate(`/app/QA/home`);
  }


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
  
      try {
        const storedTimeRemaining = localStorage.getItem("time_remaining");
  
        if (storedTimeRemaining && new Date().getTime() < storedTimeRemaining) {
          const remainingSeconds = Math.floor(
            (storedTimeRemaining - new Date().getTime()) / 1000
          );
          setRemainingTime(remainingSeconds);
        } else {
          const response = await fetch(`https://localhost/api/q/a/get`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ code: room_ref }),
          });
  
          const data = await response.json();
  
          if (data.status === 200) {
            setDataRoom(data.dataRoom);
            localStorage.setItem("DataRoom", JSON.stringify(data.dataRoom));
  
            if (data.dataRoom && data.dataRoom.time_room) {
              const timeRoomInSeconds = data.dataRoom.time_room * 60;
              setRemainingTime(timeRoomInSeconds);
  
              localStorage.setItem(
                "time_remaining",
                new Date().getTime() + timeRoomInSeconds * 1000
              );
            }
          } else {
            localStorage.setItem("DataRoom", "{}");
            toast.error(data.message);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (room_ref) {
      fetchData();
    }
  }, [room_ref]);
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://localhost/api/q/a/get_qa_question`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token_qa}`,
            },
            body: JSON.stringify({ code: room_ref }),
          }
        );
        const data = await response.json();
        if (data.status === 200) {
          setdataQuestion(data.dataQuestion);
        } else {
          alert(data.message);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    if (room_ref && token_qa) {
      fetchData();
    }
  }, [room_ref, token_qa]);

  const [playOn] = useSound('/sounds/pop-up-on.mp3', { volume: 1 });

  const [playOff] = useSound('/sounds/pop-up-off.mp3', { volume: 1 });

  const handleSelectChange = (questionId, event) => {
    console.log('event:', event); // Add this line
    setSelectedAnswers((prevAnswers) => {
      const updatedAnswers = { ...prevAnswers, [questionId]: event.target.value };
      return updatedAnswers;
    });
  };
  
  const handleSubmitAnswers = async () => {
    try {
      // Modify the mapping to include questionId and answer
      const filteredAnswers = Object.entries(selectedAnswers).map(([questionId, answer]) => ({
        questionId,
        answer,
        questionId: dataQuestion.find(q => q.id === questionId)?.id_question,
      }));
  
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/q/a/submit_answers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token_qa}`,
        },
        body: JSON.stringify({
          code: room_ref,
          answers: filteredAnswers,
        }),
      });
  
      const responseData = await response.json();
      if (responseData.status === 200) {
        setAnswers(false);
        setAnswersText(responseData);
      } else {
        alert(responseData.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const handlePreviousQuestion = () => {
    // Move to the previous question
    setCurrentQuestionIndex(Math.max(currentQuestionIndex - 1, 0));
    playOff();
    
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    playOn();
  };

  const answerSelected = selectedAnswers.hasOwnProperty(dataQuestion[currentQuestionIndex]?.id);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    const timer = setInterval(async () => { // ตรงนี้ระบุให้เป็น async
      const currentTime = new Date().getTime();
      const timeRemaining = localStorage.getItem("time_remaining");
      if (timeRemaining) {
        const remainingSeconds = Math.max(
          0,
          Math.floor((timeRemaining - currentTime) / 1000)
        );
        setRemainingTime(remainingSeconds);
  
        // เช็คว่าหมดเวลาแล้วหรือไม่
        if (remainingSeconds <= 0) {
          setStatus(false);
          try {
            const requestData = {
              code : room_ref,
              TokenAuth,
            };
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/q/a/answerstimeOut`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestData),
            });
  
            setLoading(false);
  
            const data = await response.json();
            if (response.ok) {
              setTimeout(() => {
                localStorage.removeItem('token_qa');
                localStorage.removeItem('room_ref');
                localStorage.removeItem('DataRoom');
                localStorage.removeItem('time_remaining');
                localStorage.removeItem('code');
                localStorage.removeItem("TokenAuth");
                toast.error(data.message, {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                navigate(`/app/QA/home`);
              }, 3000);

              
            } else {
              toast.error(data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          } catch (error) {
            alert("เกิดข้อผิดพลาด: ", error);
          }
          clearInterval(timer);
        }
      }
    }, 1000);
  
    return () => {
      clearInterval(timer);
    };
  }, []);

  const sendCkUSERPLAY = async () => {
    try {
      const requestData = {
        TokenAuth,
      };
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/q/a/ck_user_play`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      if (data.status == 400 || data.status == 403 || data.status == 404) {
        localStorage.removeItem('token_qa');
        localStorage.removeItem('room_ref');
        localStorage.removeItem('DataRoom');
        localStorage.removeItem('time_remaining');
        localStorage.removeItem('code');
        localStorage.removeItem("TokenAuth");
        toast.error(data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate(`/app/QA/home`);
      }
    } catch (error) {
      // console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(sendCkUSERPLAY, 1000);
    return () => clearInterval(intervalId);
  }, []);
  

  return (
    <>
    <ToastContainer />
      <AppBarQA />
      <style>
        {`
        body {
            background-image: url("${data?.img_room || DataRoom?.img_room || ""}");
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: center;
            background-size: cover;
            overflow-x: hidden;
            backdrop-filter: blur(10px);
            border-radius: 1vh;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
          }
         
        `}
      </style>
      <Container component="main" maxWidth="sm">
        <CssBaseline />


        {status && room_ref ? (
          <>
          {answers ? (
            <>
            <Box
              sx={{
                paddingTop: "25%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                style={{
                  color: data?.color_room || DataRoom?.color_room || "",
                }}
              >
                ห้อง : {data?.name_room || DataRoom?.name_room || "Q&A"}
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ paddingTop: "5%" }}
                style={{
                  color: data?.color_room || DataRoom?.color_room || "",
                }}
              >
                เวลาที่เหลือทำแบบทดสอบอีก: {formatTime(remainingTime)}
              </Typography>
            </Box>
            <Box
              sx={{
                paddingTop: "8%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <Grid container spacing={0} sx={{
                  display: "flex",
                  paddingTop: "8%",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}>
                  {dataQuestion
                    .filter(
                      (_, index) =>
                        index === currentQuestionIndex
                    )
                    .map((data, index) => (
                        <Paper
                        p="50"
                          key={data?.id}
                          sx={{
                            p: 5,
                            height: "100%",
                            width: "450px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                            <img src={data.question_img || ""} />
                          <Typography
                            component="p"
                            variant="h5"
                            sx={{
                              marginTop: 2,
                              color:
                                data?.color_room ||
                                DataRoom?.color_room ||
                                "",
                              fontWeight: 500,
                            }}
                          >
                            ข้อที่ {data.question_number} : {data.question_name}
                          </Typography>
                          
                          <FormControl
                            sx={{ width: "100%", marginTop: 2 }}
                          >
                            <InputLabel
                              id={`select-label-${data.id}`}
                            >
                              เลือกคำตอบ
                            </InputLabel>
                            <Select
                              labelId={`select-label-${data.id}`}
                              id={`select-${data.id}`}
                              value={
                                selectedAnswers[data.id] || ""
                              }
                              label="เลือกคำตอบ"
                              onChange={(event) =>
                                handleSelectChange(
                                  data.id,
                                  event
                                )
                              }
                            >
                              <MenuItem value="A">
                                {data.answer_a}
                              </MenuItem>
                              <MenuItem value="B">
                                {data.answer_b}
                              </MenuItem>
                              <MenuItem value="C">
                                {data.answer_c}
                              </MenuItem>
                              <MenuItem value="D">
                                {data.answer_d}
                              </MenuItem>
                            </Select>
                          </FormControl>

                          <Typography
                            component="p"
                            variant="subtitle2"
                            sx={{
                              marginTop: 2,
                              color:
                                data?.color_room ||
                                DataRoom?.color_room ||
                                "",
                              fontWeight: 500,
                            }}
                          >
                            Question : {data?.question_point} Point
                          </Typography>

                          <Typography
                            component="p"
                            variant="caption"
                            sx={{
                              marginTop: 2,
                              color:
                                data?.color_room ||
                                DataRoom?.color_room ||
                                "",
                              fontWeight: 500,
                            }}
                          >
                            Question ID : {data?.id_question}
                          </Typography>

                        </Paper>
                    ))}
                </Grid>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  paddingTop: "8%",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 2,
                }}
              >
                {/* Back button */}
                <Button
                  variant="contained"
                  onClick={handlePreviousQuestion}
                  disabled={currentQuestionIndex === 0}
                  sx={{ width: "48%", bgcolor: data?.color_room || DataRoom?.color_room || ""  }}
                >
                  ย้อนกลับ
                </Button>
                {/* Next button */}
                {currentQuestionIndex <
                  dataQuestion.length - 1 && (
                  <Button
                    variant="contained"
                    onClick={handleNextQuestion}
                    disabled={!answerSelected}
                    sx={{ width: "48%", bgcolor: data?.color_room || DataRoom?.color_room || "" }}
                  >
                    ถัดไป
                  </Button>
                )}
              </Box>
              {/* Submit button */}
              {currentQuestionIndex ===
                dataQuestion.length - 1 && (
                <Button
                  variant="contained"
                  onClick={handleSubmitAnswers}
                  sx={{ marginTop: 2, width: "100%", bgcolor: data?.color_room || DataRoom?.color_room || ""  }}
                >
                  ส่งคำตอบ
                </Button>
              )}
            </Box>
            </>

            ) : (
            <>
            <Box
              sx={{
                paddingTop: "25%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                style={{
                  color: data?.color_room || DataRoom?.color_room || "",
                }}
              >
                ห้อง : {data?.name_room || DataRoom?.name_room || "Q&A"}
              </Typography>
            </Box>

            <Grid container spacing={0} sx={{
                  paddingTop: "8%",
                }}>
              <Paper
                p={5}
                  key={data?.id}
                  sx={{
                    p: 5,
                    height: "100%",
                    width: "500px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
               <h1>คะแนนเต็ม : {answersText.totalPoints}</h1>
                <hr />
                <h1>คะแนนที่ได้ : {answersText.earnedPoints}</h1>

                {Object.entries(answersText.selectedAnswers).map(([questionId, answerData], index) => (
                  <Box key={questionId} sx={{ paddingTop: "8%", }}>
                      <h3>ข้อที่: {index + 1}</h3>
                      <p>คำถามไอดี: {questionId}</p>
                      <p>คำถามที่เลือก: ข้อ {answerData.selectedAnswer}</p>
                      <p>คำตอบที่ถูกต้อง: ข้อ {answerData.correctAnswer}</p>
                      <p>ข้อนี้: {answerData.isCorrect}</p>
                      <small>true = ถูก || false = ผิด</small>
                  </Box>
              ))}
                </Paper>
                </Grid>
            </>
            )}
          </>
        ) : (
          <>
            <Box
              sx={{
                paddingTop: "15%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
               <Box sx={{ paddingTop: "8%", }}>
                <Alert severity="error">
                  <AlertTitle>แจ้งเตือน</AlertTitle>
                  หมดเวลาทำแบบทดสอบ / ดูผลได้ที่แจ้งเตือน
                </Alert>  
                <CircularProgress />
              </Box>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default Play;
