import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  CircularProgress,
  Backdrop,
  Badge,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AppBarAdmin from "../../components/AppBarAdmin";
import { styled } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `2px solid ${theme.palette.background.paper}`,
}));


const ProfilePage = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const [isImagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true)
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/user`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            const data = await response.json();
            if (data.status === 200) {
             setLoading(false)
              setUser(data.user)
              return;
            } else if (data.status === 403 || data.status === 401) {
              setLoading(true)
              localStorage.removeItem('token')
              navigate('/app/backend/login');
              return;
            } 
    };
    fetchData(); // เรียกใช้ฟังก์ชัน fetchData
}, []);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedUser((prevEditedUser) => ({
      ...prevEditedUser,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = async () => {
    setBackdrop(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/change-password`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editedUser),
      });

      const data = await response.json();

      if (data.status === 200) {
        toast.success(data.message, {
          position: 'bottom-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setEditedUser({ oldPassword: '', newPassword: '', newPasswordConfirm: '' });
        setIsEditing(false);
      } else if (data.status === 401) {
        toast.error(data.message, {
          position: 'bottom-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <AppBarAdmin />
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box sx={{ marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
         <Avatar
            alt={user.acc_firstname}
            src={'https://obs.line-scdn.net/0hMBnWk0qZEm1TLgCwSMFtOgFzGQ9gTAxmcRoGCjNSTjp9TSNyJho7D3ZRTwB9YQ1QJCk7SjBRPjZ_fzNbMx04Cj9GKjk4TSxQMCA7Aj1-Og84ZQlhZzQt/f256x256'}
            sx={{ width: 100, height: 100, margin: '0 auto' }}
          />
          
          <h1>{isEditing ? 'แก้ไขรหัสผ่าน' : 'โปรไฟล์'}</h1>
          <Box component="form" noValidate sx={{ mt: 1, width: "50vh" }} >
            {loading ? (
              <CircularProgress sx={{ color: '#80B7A2' }} />
            ) : isEditing ? (
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="oldPassword"
                  label="รหัสผ่านเดิม"
                  type="password"
                  id="oldPassword"
                  value={editedUser.oldPassword}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    style: { color: '#80B7A2' },
                  }}
                  error={!editedUser.oldPassword}
                  helperText={!editedUser.oldPassword && 'กรุณากรอกรหัสผ่านเดิม'}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="รหัสผ่านใหม่"
                  type="password"
                  id="newPassword"
                  value={editedUser.newPassword}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    style: { color: '#80B7A2' },
                  }}
                  error={!editedUser.newPassword}
                  helperText={!editedUser.newPassword && 'กรุณากรอกรหัสผ่านใหม่'}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="newPasswordConfirm"
                  label="ยืนยันรหัสผ่านใหม่"
                  type="password"
                  id="newPasswordConfirm"
                  value={editedUser.newPasswordConfirm}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    style: { color: '#80B7A2' },
                  }}
                  error={!editedUser.newPasswordConfirm}
                  helperText={!editedUser.newPasswordConfirm && 'กรุณากรอกยืนยันรหัสผ่านใหม่'}
                />
                <Button
                  fullWidth
                  variant="contained"
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#80B7A2",
                    "&:hover": {
                        backgroundColor: "#637870",
                    },
                  }}
                  onClick={handleSave}
                >
                  บันทึก
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  disableElevation
                  sx={{ mt: 2 }}
                  onClick={handleCancel}
                >
                  ยกเลิก
                </Button>
              </>
            ) : (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#80B7A2",
                    "&:hover": {
                        backgroundColor: "#637870",
                    },
                  }}
                  onClick={handleEdit}
                >
                  แก้ไขรหัสผ่าน
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Container>


    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ProfilePage;
