import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Badge } from "flowbite-react";
import { Alert } from "flowbite-react";

import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import {
  CardContent,
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
  Grid,
  Card,
  CircularProgress,
  Avatar,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MenuAppBar from "../components/AppBar";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import Webcam from "react-webcam";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const HistoryOutDetail = () => {
  const [detail, setDetail] = useState(null);
  const [user, setUser] = useState("");
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  // เพิ่ม state สำหรับเก็บรูปที่ถ่าย
  const [capturedImage, setCapturedImage] = useState(null);
  const [isCaptured, setIsCaptured] = useState(false);
  const [progres, setProgress] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/user_detail`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: id,
            }),
          }
        );
        const data = await response.json();
        if (data.status === 200) {
          setDetail(data.detail);
        } else if (data.status === 404) {
          alert("คุณไม่สามารถเข้ารายการออกของผู้อื่นได้");
          navigate("/app/home");
        } else if (data.status === 403) {
          alert(data.message);
          navigate("/app/home");
        }
      } catch (error) {
        localStorage.removeItem("token");
        navigate("/app/login");
      }
    };
    fetchData();
  }, [id, token, navigate]);

  const currentPath = window.location.pathname;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
        }
      })
      .catch((error) => {
        localStorage.removeItem("token");
        navigate(`/app/login?callbackUrl=${currentPath}`);
        alert(
          "คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        );
      });
  }, [token, navigate]);

  const rooms = [
    "หน้าห้องวิชาการ",
    "หน้าห้องสมุด",
    "หน้าห้องธุระการ",
    "หน้าห้องประชาสัมพันธ์",
    "หน้าห้องสังคม",
    "หน้าโรงรถ",
    "หน้าหอประชุม",
    "หน้าอาคารม่อยจอง",
    "หน้าร้านกาแฟโรงเรียน",
    "หน้าป้ายโรงเรียนทิศเหนือ",
    "หน้าป้ายโรงเรียนทิศใต้",
  ];

  const [selectedRoom, setSelectedRoom] = useState("");

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedRoom(selectedValue);
  };

  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    // console.log(imageSrc);
    setCapturedImage(imageSrc);
    setIsCaptured(true);
  }, [webcamRef]);

  const handleUpload = async () => {
    if (!selectedRoom || !capturedImage) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    setLoading(true);
    // ทำการแปลงข้อมูลรูปภาพเป็น Blob หรือ File แล้วทำการอัพโหลดไปที่เซิร์ฟเวอร์
    const imageBlob = await fetch(capturedImage).then((res) => res.blob());

    // ตัวอย่างการใช้ FormData เพื่อสร้าง form สำหรับการอัพโหลด
    const formData = new FormData();
    formData.append(
      "image",
      imageBlob,
      `${id}-${user.acc_username}-kyc_student.jpg`
    );

    try {
      // Step 1: Upload image to /upload
      const uploadResponse = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT_NODE}/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!uploadResponse.ok) {
        alert("เกิดข้อผิดพลาดในการอ้พโหลดรูปภาพ ( Firebasestorage )");
        setLoading(false);
        return;
      }

      // Step 2: Get the uploaded image URL
      const { fileUrl } = await uploadResponse.json();

      // Step 3: Send additional data to /api/kyc
      const kycData = {
        url: fileUrl,
        selectedRoom: selectedRoom,
        id: id,
      };

      const kycResponse = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/kyc`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },

          body: JSON.stringify(kycData),
        }
      );
      const data = await kycResponse.json();
      if (kycResponse.ok) {
        toast.success(data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      } else {
        toast.error(data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert("Error during upload and KYC data sending:", error);
    }
  };

  const handleRetake = () => {
    setCapturedImage(null);
    setIsCaptured(false);
  };

  return (
    <>
      <MenuAppBar />
      <div className="min-h-screen flex justify-center items-center p-8 text-gray-900">
        <div class="w-96 max-w-sm ">
          <Box
            sx={{
              paddingTop: "35%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ alignItems: "center", justifyContent: "flex-start" }}>
              <span onClick={() => navigate("/app/")}>หน้าแรก</span> /{" "}
              <span onClick={() => navigate("/app/HistoryOut")}>
                ประวัติออกการออก
              </span>{" "}
              / รายละเอียดการออก #{id}
            </div>

            {detail ? (
              <>
                <Avatar sx={{ m: 1, bgcolor: "#ec407a" }}>
                  <InfoTwoToneIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  รายละเอียดขอผู้ขออนุญาต #{id}
                </Typography>
                <Box component="form" noValidate sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <input
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        value={`${user.acc_firstname} ${user.acc_surname}`}
                        fullWidth
                        label="ชื่อ - สกุล"
                        disabled
                        InputLabelProps={{
                          style: { color: "#ec407a" },
                        }}
                        sx={{
                          "& input": {
                            color: "#ec407a",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <input
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        fullWidth
                        label="รหัสประจำตัว"
                        value={user.acc_username}
                        autoComplete="family-name"
                        disabled
                        InputLabelProps={{
                          style: { color: "#ec407a" },
                        }}
                        sx={{
                          "& input": {
                            color: "#ec407a",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        fullWidth
                        label="มัธยมศึกษาปีที่"
                        value={user.acc_class_room}
                        autoComplete="family-name"
                        disabled
                        InputLabelProps={{
                          style: { color: "#ec407a" },
                        }}
                        sx={{
                          "& input": {
                            color: "#ec407a",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="h1" variant="h5">
                        ข้อมูลการขอออกไปนอกโรงเรียน
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        fullWidth
                        label="เหตุผลออกนอกโรงเรียน"
                        value={detail.reason}
                        autoComplete="family-name"
                        disabled
                        InputLabelProps={{
                          style: { color: "#ec407a" },
                        }}
                        sx={{
                          "& input": {
                            color: "#ec407a",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <input
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        autoComplete="given-name"
                        value={`${detail.time_out} น.`}
                        fullWidth
                        label="เวลาออก"
                        disabled
                        InputLabelProps={{
                          style: { color: "#ec407a" },
                        }}
                        sx={{
                          "& input": {
                            color: "#ec407a",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <input
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        fullWidth
                        autoComplete="given-name"
                        value={`${detail.time_in} น.`}
                        label="เวลากลับ"
                        disabled
                        InputLabelProps={{
                          style: { color: "#ec407a" },
                        }}
                        sx={{
                          "& input": {
                            color: "#ec407a",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        fullWidth
                        label="การเดินทาง"
                        value={detail.travel}
                        autoComplete="family-name"
                        disabled
                        InputLabelProps={{
                          style: { color: "#ec407a" },
                        }}
                        sx={{
                          "& input": {
                            color: "#ec407a",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Card xs={12} sm={6} sx={{ mt: 3 }}>
                  {detail.all_status_thai === "อนุมัติ" && (
                    <>
                      {detail.travel === "เดินเท้า" && (
                        <section
                          class="bg-center bg-no-repeat bg-gray-700 bg-blend-multiply"
                          style={{ backgroundImage: "url(/img/mt-in.png)" }}
                        >
                          <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-24">
                            <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-5xl">
                              ได้รับอนุญาตให้
                            </h1>
                            <p class="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-18">
                              เดินเท้า
                            </p>
                            <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
                              <div
                                href="#"
                                class="inline-flex justify-center items-center py-3 px-2 text-base font-medium text-center text-white rounded-lg bg-pink-700 focus:ring-4 focus:ring-pink-300"
                              >
                                แสดงหน้านี้ทุกครั้งเมื่อเจอครูด้านนอกโรงเรียน
                                <svg
                                  class="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 14 10"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1 5h12m0 0L9 1m4 4L9 9"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </section>
                      )}

                      {detail.travel === "ผู้ปกครองมารับ" && (
                        <section
                          class="bg-center bg-no-repeat bg-gray-700 bg-blend-multiply"
                          style={{ backgroundImage: "url(/img/mt-in.png)" }}
                        >
                          <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-24">
                            <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-5xl">
                              ได้รับอนุญาตให้
                            </h1>
                            <p class="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-18">
                              ผู้ปกครองมารับ
                            </p>
                            <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
                              <div
                                href="#"
                                class="inline-flex justify-center items-center py-3 px-2 text-base font-medium text-center text-white rounded-lg bg-pink-700 focus:ring-4 focus:ring-pink-300"
                              >
                                แสดงหน้านี้ทุกครั้งเมื่อเจอครูด้านนอกโรงเรียน
                                <svg
                                  class="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 14 10"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1 5h12m0 0L9 1m4 4L9 9"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </section>
                      )}

                      {detail.travel === "จักรยานยนต์" && (
                        // <CardContent sx={{ textAlign: "center" }}>
                        //   <Typography
                        //     sx={{ fontSize: 14 }}
                        //     color="text.secondary"
                        //     gutterBottom
                        //   >
                        //     รหัสอ้างอิงประตูโรงรถ : {detail.referral_code}
                        //   </Typography>
                        //   <Typography variant="h5" component="div">
                        //     {detail.code}
                        //   </Typography>
                        //   <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        //     รหัสเปิดประตูโรงรถ
                        //   </Typography>
                        //   <Typography variant="body2">
                        //     รหัสนี้ใช้ได้แค่ครั้งเดียวเท่านั้นไม่สามารถนำกลับมาใช้ใหม่ได้
                        //     <br />
                        //     {'"แสดงหน้านี้ทุกครั้งเมื่อเจอครูด้านนอกโรงเรียน"'}
                        //   </Typography>
                        // </CardContent>

                        <section
                          class="bg-center bg-no-repeat bg-gray-700 bg-blend-multiply"
                          style={{ backgroundImage: "url(/img/mt-in.png)" }}
                        >
                          <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-24">
                            <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-5xl">
                              ได้รับอนุญาตให้
                            </h1>
                            <p class="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-18">
                              {" "}
                              นำ จักรยานยนต์ ออกไป
                            </p>
                            <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
                              <div
                                href="#"
                                class="inline-flex justify-center items-center py-3 px-2 text-base font-medium text-center text-white rounded-lg bg-pink-700 focus:ring-4 focus:ring-pink-300"
                              >
                                แสดงหน้านี้ทุกครั้งเมื่อเจอครูด้านนอกโรงเรียน
                                <svg
                                  class="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 14 10"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1 5h12m0 0L9 1m4 4L9 9"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </section>
                      )}
                    </>
                  )}

                  {(detail.all_status_thai == "ไม่อนุมัติ" ||
                    detail.all_status_thai === "ครูที่ปรึกษาไม่อนุมัติ" ||
                    detail.all_status_thai ===
                      "ครูฝ่ายกิจการนักเรียนไม่อนุมัติ") && (
                    <section
                      class="bg-center  bg-no-repeat bg-gray-700 bg-blend-multiply"
                      style={{ backgroundImage: "url(/img/mt-in.png)" }}
                    >
                      <div class="px-4 text-center w-full py-24 lg:py-24">
                        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-5xl">
                          ไม่อนุมัติ
                        </h1>
                        <div class="flex flex-col space-y-4 sm:justify-center ">
                          <div
                            href="#"
                            class="inline-flex justify-center items-center py-3 px-2 text-base font-medium text-center text-white rounded-lg bg-pink-700 focus:ring-4 focus:ring-pink-300"
                          >
                            สถานะครูประจำชั้น : {detail.class_instructor}
                          </div>
                          <div
                            href="#"
                            class="inline-flex justify-center items-center py-3 px-2 text-base font-medium text-center text-white rounded-lg bg-pink-700 focus:ring-4 focus:ring-pink-300"
                          >
                            สถานะครูกิจการนักเรียน :{" "}
                            {detail.student_affairs_teacher}
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  {(detail.all_status_thai ===
                    "รอครูฝ่ายกิจการนักเรียนอนุมัติ" ||
                    detail.all_status_thai === "รอครูที่ปรึกษาอนุมัติ" ||
                    detail.all_status_thai ===
                      "รอ ครูฝ่ายกิจการ และ ครูที่ปรึกษา อนุมัติ") && (
                    <section
                      class="bg-center  bg-no-repeat bg-gray-700 bg-blend-multiply"
                      style={{ backgroundImage: "url(/img/mt-in.png)" }}
                    >
                      <div class="px-4 text-center w-full py-24 lg:py-24">
                        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-5xl">
                          โปรดรอครูอนุมัติ
                        </h1>
                        <div class="flex flex-col space-y-4 sm:justify-center ">
                          <div
                            href="#"
                            class="inline-flex justify-center items-center py-3 px-2 text-base font-medium text-center text-white rounded-lg bg-pink-700 focus:ring-4 focus:ring-pink-300"
                          >
                            สถานะครูประจำชั้น : {detail.class_instructor}
                          </div>
                          <div
                            href="#"
                            class="inline-flex justify-center items-center py-3 px-2 text-base font-medium text-center text-white rounded-lg bg-pink-700 focus:ring-4 focus:ring-pink-300"
                          >
                            สถานะครูกิจการนักเรียน :{" "}
                            {detail.student_affairs_teacher}
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </Card>

                {detail.all_status_thai === "อนุมัติ" && (
                  <CardContent sx={{ textAlign: "center" }}>
                    {detail.kyc === "false" ? (
                      <>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          แจ้งเตือน :
                          หลังกลับมาถึงโรงเรียนต้องยืนยันตัวตนการกลับมาโดยการถ่ายรูปเพื่อเป็นหลักฐานการกลับมา
                        </Typography>

                        <form class="max-w-sm mx-auto">
                          <label
                            for="countries"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            กรุณาเลือกสถานที่การยืนยันตัวตน
                          </label>
                          <select
                            id="countries"
                            onChange={handleChange}
                            value={selectedRoom}
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5"
                          >
                            <option selected>
                              กรุณาเลือกสถานที่การยืนยันตัวตน
                            </option>
                            {rooms.map((room, index) => (
                              <option value={room}> {room}</option>
                            ))}
                          </select>
                        </form>
                        <Typography
                          sx={{ fontSize: 14, marginTop: 2 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          หลังจากเลือกแล้ว
                          ให้ถ่ายรูปที่เห็นตัวนักเรียนชัดเจนแล้ว
                          (รูปภาพฟรีสไตล์) อัพโหลดเพื่อยืนยันตัวตน
                        </Typography>

                        {isCaptured ? (
                          <>
                            <Box component="form" noValidate sx={{ mt: 3 }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <img
                                    src={capturedImage}
                                    className="rounded-lg drop-shadow-lg"
                                    alt="capturedImage"
                                  />
                                </Grid>
                              </Grid>
                            </Box>

                            <button
                              disabled={loading}
                              className={`w-full font-medium mt-3 rounded-lg text-sm px-5 py-2.5 text-center bg-pink-800 hover:bg-pink-900 focus:ring-pink-800`}
                              onClick={handleUpload}
                              style={{ color: "#ffffff" }}
                            >
                              {loading ? (
                                <>
                                  <svg
                                    aria-hidden="true"
                                    class="inline w-6 h-6 text-gray-50 animate-spin fill-gray-900"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                </>
                              ) : (
                                <>
                                  <i class="bi bi-arrow-up-right-square-fill animate-spin"></i>{" "}
                                  ดำเนินการต่อ
                                </>
                              )}
                            </button>
                            <button
                              disabled={loading}
                              className={`bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5`}
                              onClick={handleRetake}
                            >
                              {loading ? (
                                <>
                                  <svg
                                    aria-hidden="true"
                                    class="inline w-6 h-6 text-gray-50 animate-spin fill-gray-900"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                </>
                              ) : (
                                <>
                                  <i className="bi bi-x-circle"></i> ถ่ายรูปใหม่
                                </>
                              )}
                            </button>
                          </>
                        ) : (
                          <>
                            <Webcam
                              sx={{ textAlign: "center" }}
                              width={"100%"}
                              height={"100%"}
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              className="rounded-lg drop-shadow-lg"
                            />
                            <button
                              disabled={loading}
                              className={`w-full font-medium mt-4 rounded-lg text-sm px-5 py-2.5 text-center bg-pink-800 hover:bg-pink-900 focus:ring-pink-800`}
                              onClick={capture}
                              style={{ color: "#ffffff" }}
                            >
                              {loading ? (
                                <>
                                  <svg
                                    aria-hidden="true"
                                    class="inline w-6 h-6 text-gray-50 animate-spin fill-gray-900"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                </>
                              ) : (
                                <>
                                  <i class="bi bi-arrow-up-right-square-fill animate-spin"></i>{" "}
                                  ดำเนินการต่อ
                                </>
                              )}
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Badge color="success" size="sm">
                          {" "}
                          แจ้งเตือน : ได้ทำการยืนยันตัวตนแล้ว
                        </Badge>
                        <Box component="form" noValidate sx={{ mt: 3 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <img
                                class="h-auto max-w-lg rounded-lg"
                                src={detail.kyc_img}
                                alt="ได้ทำการยืนยันตัวตนแล้ว"
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Badge color="info" size="sm" className="mt-3">
                          สถานที่ยืนยันตัวตน : {detail.kyc_location}
                        </Badge>
                      </>
                    )}
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ marginTop: 5 }}
                    ></Typography>
                    <Alert color="failure">
                      <i className="bi bi-x-circle-fill"></i> หาก
                      <span className="font-medium">ข้อมูลส่งเท็จ</span>
                      ทางกิจการนักเรียนจะทำการเรียกเป็นขั้นตอนต่อไป
                    </Alert>
                  </CardContent>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  MTwit Service's Bata
                </Typography>
                <Typography variant="h6" gutterBottom>
                  กำลังดึงข้อมลู....
                </Typography>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default HistoryOutDetail;
