import React, { useState, useEffect } from "react";
import { Container, Avatar } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

import MenuAppBar from "../components/AppBar";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  CssBaseline,
  Chip,
} from "@mui/material";

import FaceIcon from "@mui/icons-material/Face";
import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined";

const UserTable = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = React.useState(false);
  const token = localStorage.getItem("token");
  const [user, setUser] = useState({});

  useEffect(() => {
    setOpen(true);
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
          setOpen(false);
        } else {
          setOpen(true);
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        setOpen(true);
        localStorage.removeItem("token");
      });
  }, []);

  const getClassRoomInfo = (classRoomString) => {
    if (classRoomString) {
      const classRoom = classRoomString.split("/");
      const classNumber = classRoom[0];
      const roomNumber = classRoom[1];
      return { classNumber, roomNumber };
    } else {
      return { classNumber: "", roomNumber: "" };
    }
  };

  const { classNumber, roomNumber } = getClassRoomInfo(user.acc_class_room);

  const fetchData = async () => {
    const currentPath = window.location.pathname;
    try {
      setOpen(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/history_out`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOpen(false);
      setUsers(response.data);
    } catch (error) {
      setOpen(true);
      localStorage.removeItem("token");
      navigate(`/app/login?callbackUrl=${currentPath}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "reason",
      headerName: "เหตุผล",
      width: 500,
    },
    {
      field: "time_out",
      headerName: "เวลาไป",
      width: 100,
    },
    {
      field: "time_in",
      headerName: "เวลากลับ",
      width: 100,
    },
    {
      field: "all_status_thai",
      headerName: "สถานะ",
      width: 150,
    },
    {
      field: "KYC",
      headerName: "การยืนยันตัวตน",
      width: 200,
      renderCell: (params) => (
        <>
          {params.row.all_status_thai === "รอครูฝ่ายกิจการนักเรียนอนุมัติ" ||
          params.row.all_status_thai === "รอครูที่ปรึกษาอนุมัติ" ||
          params.row.all_status_thai ===
            "รอ ครูฝ่ายกิจการ และ ครูที่ปรึกษา อนุมัติ" ? (
            <>
              <Button
                onClick={() => handleViewClick(params.row.id)}
                variant="text"
              >
                <Chip
                  icon={<HourglassBottomOutlinedIcon />}
                  color="warning"
                  label="รออนุมัติ"
                  variant="outlined"
                />
              </Button>
            </>
          ) : null}

          {params.row.all_status_thai === "อนุมัติ" ? (
            <>
              {params.row.kyc === "false" ? (
                <Button
                  onClick={() => handleViewClick(params.row.id)}
                  variant="text"
                >
                  <Chip
                    icon={<FaceIcon />}
                    color="warning"
                    label="รอการยืนยันตัวตน"
                    variant="outlined"
                  />
                </Button>
              ) : (
                <Button variant="text">
                  <Chip
                    icon={<FaceIcon />}
                    color="success"
                    label="ได้ทำการยืนยันตัวตนแล้ว"
                    variant="outlined"
                  />
                </Button>
              )}
            </>
          ) : null}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "จัดการ",
      width: 150,
      renderCell: (params) => (
        <>
          <Button onClick={() => handleViewClick(params.row.id)} variant="text">
            ดูรายละเอียด
          </Button>
        </>
      ),
    },
  ];

  const handleViewClick = (id) => {
    setOpen(true);
    setTimeout(() => {
      navigate(`/app/detail/${id}`);
    }, 2000);
  };
  return (
    <>
      <MenuAppBar />

      <div className="min-h-screen flex justify-center items-center p-8 text-gray-900">
        <div style={{ height: 400, width: "100%" }}>
          <div className="i-container mr-4 ">
            <div style={{ minWidth: "120px" }}>
              <img
                src={user.acc_line_pictureUrl || "/img/MTwit.jpg"}
                alt="Logo_Game"
                className="p-1 rounded-full ring-2 ring-gray-300 "
                width={96}
                height={96}
              />
            </div>
            <div
              className="i-container"
              style={{
                justifyContent: "flex-start",
                minWidth: "200px",
              }}
            >
              <div style={{ width: "100%" }}>
                <h1 className="text-xl font-bold">ประวัติออกนอกโรงเรียน </h1>
                <h1 className="text-sm text-gray-500">
                  มีแค่ออกนอกโรงเรียน ไม่มีวันออกไปจูงมือเขาหรอก
                </h1>
                <h1 className="text-2xl font-bold">
                  ของ {user.acc_firstname} {user.acc_surname}
                </h1>
                <h6 className="mb-0">รหัสประจำตัว : {user.acc_username}</h6>
                {user.acc_class_room && (
                  <>
                    <h6 className="mb-0">
                      นักเรียนมัธยมศึกษาปีที่: {classNumber} ห้อง {roomNumber}
                    </h6>
                  </>
                )}
              </div>
            </div>
          </div>
          <DataGrid
            className="mt-2"
            columns={columns}
            rows={users}
            pagination
            onPageChange={(params) => handleChangePage(params.page)}
            onPageSizeChange={(params) => {
              setRowsPerPage(params.pageSize);
              setPage(0);
            }}
            pageSize={rowsPerPage}
            rowCount={users.length}
          />
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default UserTable;
