import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import AppBarAdmin from "../../components/AppBarAdmin";
import Backdrop from "@mui/material/Backdrop";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // นี่คือการ import CSS

function AdminLogin() {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!inputs.username || !inputs.password) {
      toast.warn("กรุณากรอกข้อมลูให้ครบถ้วน", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    try {
      setLoading(true);
      setBackdrop(true);
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputs),
      });

      setLoading(false);
      setBackdrop(false);

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.accessToken);
        toast.success(data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/app/backend/");
      } else {
        toast.error(data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      setLoading(false);
      alert("เกิดข้อผิดพลาดระหว่างการเข้าสู่ระบบ:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // นับถอยหลัง 5 วินาทีแล้วเปลี่ยนเส้นทาง
      const timer = setTimeout(() => {
        navigate("/app/backend/"); // หรือใช้วิธีอื่นในการเปลี่ยนเส้นทาง
      }, 1000);

      // ตอน unmount ให้เคลียร์ timeout ทิ้งเพื่อป้องกัน memory leak
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <AppBarAdmin />
      <div
        className="min-h-screen flex items-center justify-center relative mb-16"
        style={{ top: 0}}
      >
        <form onSubmit={handleSubmit}>
          <div className="w-full p-10">
            <h2 className="text-4xl text-center ">ยินดีตอนรับ</h2>
            <h6 className="text-1xl mt-2 text-center ">ผู้ดูแลระบบนักเรียนออกนอกโรงเรียนแม่ตื่นวิทยาคม</h6>

            <label className="form-control flex flex-col w-full mt-6">
              <div className="label mb-2">
                <span className="label-text-alt">
                  ชื่อผู้ใช้
                </span>
              </div>
              <input
                type="text"
                name='username'
                placeholder="XXXXXX"
                value={inputs.username || ""}
                onChange={handleChange}
                className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                required
              />
            </label>
              <label className="form-control flex flex-col w-full mt-3">
                <div className="label mb-2">
                  <span className="label-text-alt mr-3">
                    รหัสผ่าน
                  </span>
                </div>
                <input
                  type="password"
                  name='password'
                  value={inputs.password || ""}
                  onChange={handleChange}
                  placeholder="*****"
                  className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                  />
              </label>
            <div className="col mt-6 space-y-4">
              <button
                disabled={loading}
                className={`w-full font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-pink-800 hover:bg-pink-900 focus:ring-pink-800`}
                type="submit"
                style={{ color: "#ffffff" }}
              >
                {loading ? (
                <>
                <svg aria-hidden="true" class="inline w-6 h-6 text-gray-50 animate-spin fill-gray-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg></>
              ) : (
                <><i class="bi bi-arrow-up-right-square-fill animate-spin"></i> ดำเนินการต่อ</>
              )}
              </button>
              <span
                className="label-text-alt text-sm w-full block"
                style={{ color: "#ad001d" }}
              >
                ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 
              </span>
            </div>
          </div>
        </form>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default AdminLogin;
