import * as React from "react";
import { useState, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Box from "@mui/material/Box";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ShareIcon from "@mui/icons-material/Share";
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate, useLocation } from "react-router-dom";

export default function SpeedDialTooltipOpen() {
  const navigate = useNavigate();



  return (


<div data-dial-init class="fixed end-6 bottom-6 group z-[1000]" onClick={() => navigate("/app/notifications")}>
    <button type="button" data-dial-toggle="speed-dial-menu-default" aria-controls="speed-dial-menu-default" aria-expanded="false" class="flex items-center justify-center text-white bg-pink-700 rounded-full w-14 h-14 hover:bg-pink-800 focus:ring-4 focus:ring-pink-300 focus:outline-none ">
        <i class="bi bi-bell w-8 h-8 transition-transform group-hover:rotate-45" style={{fontSize: 25}}></i>
        <span class="sr-only">Open actions menu</span>
    </button>
</div>

  )
}
