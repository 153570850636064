import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Typography, Box, Link as  CircularProgress } from '@mui/material';
// import 'flowbite';
import { Toaster, toast } from 'react-hot-toast';

import Index from './pages/Index';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import LineLogin from './pages/auth/LineLogin';
import Smartcard from './pages/auth/Smartcard';
import Profile from './pages/auth/Profile';
import Maintain from './pages/Maintain';
import CreateNew from './pages/CreateNew';
import HistoryOut from './pages/HistoryOut';
import Team from "./pages/Team";
import HistoryOutDetail from "./pages/HistoryOutDetail";
import NotFound from './pages/404/NotFound';
import Grade from './pages/Grade';
import LineProfile from './pages/LineProfile';
import LineConnectApi from './pages/auth/LineConnectApi';
import Emergency from './pages/Emergency';
import Setting from './pages/auth/Setting';
import Notifications from './pages/Notifications';

import QrCheckPay from './pages/Pay/QrCheckPay';
import CheckST from './pages/Pay/CheckST';
import PayFood from './pages/Pay/PayFood';


import QrCode from './pages/QrCode';

import HomeTeacher from './pages/Teacher/HomeTeacher';
import TehLogin from './pages/Teacher/TehLogin';
import TehProfile from './pages/Teacher/Profile';
import StOut from './pages/Teacher/stOut';
import HistoryOutDetailTeacher from './pages/Teacher/HistoryOutDetailTeacher';
import AllStudents from './pages/Teacher/AllStudents';
import GradeTEH from './pages/Teacher/Grade';
import LineTeacher from './pages/Teacher/LineTeacher';
import OutinfoAll from './pages/Teacher/outinfoAll';


import AdminLogin from './pages/Admin/Adminlogin';
import Adminhome from './pages/Admin/Adminhome';
import Adminprofile from './pages/Admin/Adminprofile';
import AdminallStudent from './pages/Admin/AdminallStudent';
import AdminStudentDetail from './pages/Admin/AdminStudentDetail';
import AdminallTeacher from './pages/Admin/AdminallTeacher';
import AdminTeacherDetail from './pages/Admin/AdminTeacherDetail';
import AdminSetting from './pages/Admin/AdminSetting';
import AdminStatistics from './pages/Admin/Statistics';

import HomeQA from './pages/QuestionAnswer/Home';
import Play from './pages/QuestionAnswer/Play';

import ReelHome from './pages/Reel/Home';


import AOS from 'aos';
import 'aos/dist/aos.css';
function App() {
  const [isLoading, setIsLoading] = useState(true);
  // const [isOnline, setIsOnline] = useState("1");
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: 'none', // หรือใช้ 'false'
      offset: 1,
      once: true
  });
  
  }, []);



  useEffect(() => {
    const isFirstLoad = localStorage.getItem('isFirstLoad');
    const currentTime = new Date().getTime();
    const expirationTime = localStorage.getItem('expirationTime');

    if (isFirstLoad === null || currentTime > expirationTime) {
      const newExpirationTime = currentTime + 10 * 60 * 1000; // 10 minutes
      localStorage.setItem('isFirstLoad', 'false');
      localStorage.setItem('expirationTime', newExpirationTime);
      
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const handleConnectionChange = () => {
      setIsOnline(window.navigator.onLine);
      if (!window.navigator.onLine) {
        localStorage.removeItem('isFirstLoad')
        localStorage.removeItem('expirationTime')
        toast.error('ไม่ได้เชื่อมต่ออินเตอร์เน็ต');
      } else {
        toast.success('กลับมาออนไลน์อีกครั้ง');
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);


  function Copyright() {
  
    return (
      <>
 <footer class="bg-white rounded-lg ">
              <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                  <div class="sm:flex sm:items-center sm:justify-between">
                      <div onClick={() => navigate("/app/")} class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                          <img src="/img/logomtwit-768x768 (3).png" class="h-8" alt="ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคม Logo" />
                          <span class="self-center text-sm font-semibold whitespace-nowrap ">ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคม</span>
                      </div>
                      <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 ">
                          <li>
                              <div onClick={() => navigate('/app/Team')} class="hover:underline me-4 md:me-6">ผู้จัดทำ</div>
                          </li>
                          <li>
                            <a href="https://maetuen.ac.th/" class="hover:underline me-4 md:me-6">เว็บไซต์โรงเรียน</a>
                          </li>
                          <li>
                            <a href="https://info.maetuen.ac.th/index.php" class="hover:underline me-4 md:me-6">ระบบสารสนเทศ</a>
                          </li>
                          <li>
                            <div onClick={() => navigate('/app/about')} class="hover:underline me-4 md:me-6">เกี่ยวกับระบบนี้</div>
                          </li>
                      </ul>
                  </div>
                  <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
                  <span class="block text-sm text-gray-500 sm:text-center ">© 2023 - {new Date().getFullYear()}{' '} <a href='https://hnawny.in.th/'>Hnawny Cloud House</a> DevBy <a href='https://hnaxny.xyz/'>Hnawny</a> All Rights Reserved.</span>
              </div>
          </footer>


         
      </>
    );
  }

  const location = useLocation();

  return (
    <>
      <Toaster position="bottom-left" reverseOrder={true} />
      {isLoading ? (
        <div className="hero min-h-screen flex items-center justify-center z-[1000]">
        <div className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="text-3xl font-bold">กำลังเปิด MTwit Service's</h1>
            <div role="status">
              <svg
                aria-hidden="true"
                class="mt-4 inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
            <div class="mb-1 text-base font-medium">กรุณารอสักครู่</div>
          </div>
        </div>
      </div>
      ) : (
        <>
          {!isOnline ? (
            <Box sx={{ marginTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div className="alert">
                <Typography variant="h6" gutterBottom>
                  พยายามเปิด MTwit Service's
                </Typography>
              </div>
              <div className="alert">
                <Typography variant="h6" gutterBottom>
                  กรุณาเชื่อมต่ออินเตอร์เน็ต
                </Typography>
              </div>
              <CircularProgress sx={{ color: '#ff699b' }} />
            </Box>
          ) : (
            <>
            <Routes>
              {/* MTwit Service's นักเรียน */}
                <Route path="/" element={<Index />} />
                <Route path="/app/" element={<Home />} />
                <Route path="/app" element={<Home />} />
                <Route path="/app/home" element={<Home />} />
                <Route path="/app/login" element={<Login />} />
                <Route path="/app/auth/line/*" element={<LineLogin />} />
                <Route path="/app/Profile" element={<Profile />} />
                <Route path="/app/Maintain" element={<Maintain />} />
                <Route path="/app/CreateNew" element={<CreateNew />} />
                <Route path="/app/HistoryOut" element={<HistoryOut />} />
                <Route path="/app/Team" element={<Team />} />
                <Route path="/app/detail/:id" element={<HistoryOutDetail />} />
                <Route path="/app/grade" element={<Grade />} />
                <Route path="/app/line/smartcard" element={<Smartcard />} />
                <Route path="/app/setting" element={<Setting />} />
                <Route path="/app/notifications" element={<Notifications />} />
               

                <Route path="/app/line/profile/" element={<LineProfile />} />
                <Route path="/app/line/login/:id" element={<LineConnectApi />} />
                <Route path="/app/emergency" element={<Emergency />} />
                <Route path="/app/QrCode" element={<QrCode />} />
              {/* MTwit Service's  นักเรียน */}

              {/* MTwit Service's  ครู */}
                <Route path="/app/teacher/home" element={<HomeTeacher />} />
                <Route path="/app/teacher/" element={<HomeTeacher />} />
                <Route path="/app/teacher" element={<HomeTeacher />} />
                <Route path="/app/teacher/teh-login" element={<TehLogin />} />
                <Route path="/app/teacher/profile" element={<TehProfile />} />
                <Route path="/app/teacher/stOut" element={<StOut />} />
                <Route path="/app/teacher/allstudents" element={<AllStudents />} />
                <Route path="/app/teacher/grade" element={<GradeTEH />} />
                <Route path="/app/teacher/detail/:id" element={<HistoryOutDetailTeacher />} />
                <Route path="/app/teacher/Line" element={<LineTeacher />} />
                <Route path="/app/teacher/outinfoAll" element={<OutinfoAll />} />
              {/* MTwit Service's  ครู */}

              {/* MTwit Service's  แอดมิน */}
              <Route path="/app/backend/login" element={<AdminLogin />} />
              <Route path="/app/backend/" element={<Adminhome />} />
              <Route path="/app/backend/profile" element={<Adminprofile />} />
              <Route path="/app/backend/allStudent" element={<AdminallStudent />} />
              <Route path="app/backend/student/:id" element={<AdminStudentDetail />} />
              <Route path="app/backend/setting" element={<AdminSetting />} />
              <Route path="/app/backend/allTeacher" element={<AdminallTeacher />} />
              <Route path="app/backend/Teacher/*" element={<AdminTeacherDetail />} />
              <Route path="app/backend/statistics" element={<AdminStatistics />} />
              {/* MTwit Service's  แอดมิน */}


              {/* MTwit Service's  Reel */}
              <Route path="/app/QA/home" element={<HomeQA />} />
              <Route path="/app/QA/play" element={<Play />} />
              {/* MTwit Service's  Reel */}

               {/* MTwit Service's  แอดมิน */}
               <Route path="/app/reel/home" element={<ReelHome />} />
               <Route path="/app/reel/" element={<ReelHome />} />
              {/* MTwit Service's  แอดมิน */}

              <Route path="/QrCheckPay" element={<QrCheckPay />} />
              <Route path="/CheckST" element={<CheckST />} />
              <Route path="/PayFood" element={<PayFood />} />

              

              <Route path="*" element={<NotFound />} />
            </Routes>
            {location.pathname !== '/QrCheckPay' && location.pathname !== '/CheckST' && location.pathname !== '/PayFood' && <Copyright />}
          </>
          )}
        </>
      )}
    </>
  );
}

export default App;
