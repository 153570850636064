import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import domtoimage from "dom-to-image";
import "../profile.css";
import { saveAs } from "file-saver";

const LineProfile = () => {
  const token = localStorage.getItem("token");
  const profileColor = localStorage.getItem("profileColor");
  const navigate = useNavigate();
  const [resultuser, setUser] = useState({});
  const [backgroundColor, setBackgroundColor] = useState(
    profileColor || "pink"
  ); // ถ้าไม่มีค่า profileColor ใน localStorage จะให้ค่าเริ่มต้นเป็น "pink"
  const currentPath = window.location.pathname;
  const changeBackground = (color) => {
    localStorage.setItem("profileColor", color);
    setBackgroundColor(color);
  };

  const resetColor = () => {
    localStorage.removeItem("profileColor");
    setBackgroundColor("pink");
  };

  const encodedText = btoa(resultuser.acc_username);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
          if (!data.user.acc_line_user_id) {
            alert("กรุณาเชื่อมต่อกับไลน์ก่อนทำรายการในหน้านี้");
            navigate(`/app/login?callbackUrl=${currentPath}`);
          }
        } else {
          localStorage.removeItem("token");
          navigate(`/app/login?callbackUrl=${currentPath}`);
        }
      })
      .catch((error) => {
        localStorage.removeItem("token");
        navigate(`/app/login?callbackUrl=${currentPath}`);
        alert(
          "คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        );
      });
  }, []);

  const captureImage = () => {
    const divToCapture = document.getElementById("card");

    // ตั้งค่าตำแหน่งเริ่มต้นเพื่อให้ dom-to-image จับภาพได้ถูกต้อง
    divToCapture.style.position = "absolute";
    divToCapture.style.margin = "auto";

    domtoimage
      .toBlob(divToCapture)
      .then((blob) => {
        // นำค่าตำแหน่งกลับไปเป็นเดิมหลังจากจับภาพเสร็จสิ้น
        divToCapture.style.position = "absolute";
        divToCapture.style.margin = "auto";

        // สร้างชื่อไฟล์ที่สุ่ม
        const fileName = `profile_${resultuser.acc_firstname}_${resultuser.acc_surname}_${resultuser.acc_username}.png`;

        // บันทึกไฟล์
        saveAs(blob, fileName);
      })
      .catch((error) => {
        // หลังจากที่ catch เสร็จ ก็ต้องนำค่าตำแหน่งกลับมาเป็นเดิมด้วย
        divToCapture.style.position = "absolute";
        divToCapture.style.margin = "auto";

        console.error("Capture failed", error);
      });
  };

  return (
    <div
      // className="min-h-screen flex items-center justify-center relative mb-16"
      style={{ top: 0 }}
    >
      <div className="leading-tight min-h-screen bg-grey-lighter p-8">
        <div className="max-w-lg mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
          <div
            className="bg-cover h-80"
            style={{ backgroundImage: "url('/img/mt-in.png')" }}
          ></div>
          <div className="border-b px-4 pb-6">
            <div className="text-center text-left flex mb-4">
              <img
                className="h-32 w-32 rounded-full border-4 border-white -mt-16 mr-4"
                src={resultuser.acc_line_pictureUrl || "/img/MTwit.jpg"}
                alt=""
              />
              <div className="py-2">
                <h3 className="font-bold text-2xl inline-flex text-grey-dark sm:flex items-center">
                  {resultuser.acc_firstname} {resultuser.acc_surname}
                </h3>
                <div className="inline-flex text-grey-dark sm:flex items-center">
                  <b>รหัสประจำตัว :</b> {resultuser.acc_username}
                </div>
                <div className="text-grey-dark items-center mt-2">
                  <b>ชั้นมัธยมศึกษาปีที่ :</b> {resultuser.acc_class_room}
                </div>
              </div>
            </div>
            <div className="flex">
              <button
                onClick={() => navigate("/app/profile")}
                className="flex-1 rounded-full bg-pink-900 text-white antialiased font-bold hover:bg-pink-900 px-4 py-2 mr-2"
              >
                ย้อนกลับ
              </button>
              <button
                onClick={() => navigate("/app/setting")}
                className="flex-1 rounded-full border-2 border-grey font-semibold text-black px-4 py-2"
              >
                แก้ไขข้อมูล
              </button>
            </div>
          </div>
          <div className="grid place-items-center">
            <img
              src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=MTWIT.${encodedText}&choe=UTF-8`}
              width="450"
              height="450"
              alt={`${resultuser.acc_firstname} ${resultuser.acc_surname}`}
              style={{ borderRadius: "15px" }}
              title={`${resultuser.acc_firstname} ${resultuser.acc_surname}`}
            />
            <h3 className="font-bold text-2xl text-red-500 inline-flex text-grey-dark sm:flex items-center ml-3">
              แสดงเมื่อเจอครู
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineProfile;
