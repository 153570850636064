import React from "react";
import {
  Typography,
  Grid,
  Avatar,
  Box,
  ListItem,
  ListItemText,
  Divider,
  Container,
  Button,
  CssBaseline,
} from "@mui/material";

function Team() {
  const teamMembers = [
    {
      name: "GibZy Kasinee",
      stationed: "ห้องคอมพิวเตอร์ 2",
      imageUrl:
        "https://scontent.fcnx3-1.fna.fbcdn.net/v/t39.30808-1/315573800_6076470009030865_7114609034968852288_n.jpg?stp=dst-jpg_p320x320&_nc_cat=105&ccb=1-7&_nc_sid=7206a8&_nc_eui2=AeEYuuhtmtXWayjTuhJJmPIb8tOWGRxGjJvy05YZHEaMmyKG-3cmoBmyG6j1-2M3yCC3Pc1kf20vQthcQsACZAob&_nc_ohc=JmM4LukguO0AX-bIBq9&_nc_ht=scontent.fcnx3-1.fna&oh=00_AfBYjADcw5sbAcTYkNuWc7HXSarOr9g2kUQgMuwk_xnNug&oe=64E96D2F",
      role: "ครู",
    },
    {
      name: "Teerapat Tawang ʚìɞ",
      stationed: "ห้อง 6/3",
      imageUrl:
        "https://scontent.fcnx3-1.fna.fbcdn.net/v/t39.30808-6/368317764_1449594089174285_4280871816920809339_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeGJ7dHiKKLdR6QBHEAvViAR1VOrhPzHLKnVU6uE_McsqVneER-08YAkOWvZIVlIQSJzuAv9ps36ZKdZacjHCIKB&_nc_ohc=dmCQyYMnUaEAX9yQDF_&_nc_ht=scontent.fcnx3-1.fna&oh=00_AfAAhVG64bxC6ynarqqC1Ejw5_Tf0zSzEV22uusNIsy53A&oe=64EB5356",
      role: "ผู้ดูแลระบบ",
    },
    {
      name: "Nattawat Thakham",
      stationed: "ห้อง 6/4",
      imageUrl:
        "https://scontent.fcnx3-1.fna.fbcdn.net/v/t39.30808-6/356399782_1652452528592077_6844453728073393732_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeF6llu7ujbV7SoZIISso1I7szkfcVakwtqzOR9xVqTC2kdyNKw7H2czlJzUw1q2-NI5hxBsaJOsGEKrec3JuQpM&_nc_ohc=Dbmf-4GBSzgAX90DhvM&_nc_ht=scontent.fcnx3-1.fna&oh=00_AfBDVZ9aUqkDfST_SPE5PkkWrMvPXvUKj01Jon23NOvh1Q&oe=64E9CEE3",
      role: "ผู้ดูแลระบบ",
    },
    {
      name: "Sittichai Manorat",
      stationed: "ห้อง 6/1",
      imageUrl:
        "https://scontent.fcnx3-1.fna.fbcdn.net/v/t39.30808-6/330863513_1356075295194934_8763333270907975809_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=174925&_nc_eui2=AeEyeMOrelKSCxELrPUk07O4SXpEDxS-yIlJekQPFL7IiV3ARyhNtudiyM6wSxmN8kb2Yhe_GRmfRKcphiKonMFG&_nc_ohc=KqCVk3TbdN0AX-hlboV&_nc_ht=scontent.fcnx3-1.fna&oh=00_AfAVw3hYinDVRxfubkTzR1mp3nTUfMRY-Ik-3UH_gcz15A&oe=64EB1435",
      role: "ผู้ดูแลระบบ",
    },
  ];

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            ทีมงานที่ดูแลระบบ
          </Typography>
          <Grid container spacing={2}>
            {teamMembers.map((member, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12}>
                  <ListItem>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <Avatar alt={member.name} src={member.imageUrl} />
                      </Grid>
                      <Grid item>
                        <ListItemText
                          primary={member.name}
                          secondary={
                            <>
                              {member.role && (
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                >
                                  {member.role}
                                </Typography>
                              )}
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                ประจำการที่ : {member.stationed}
                              </Typography>
                            </>
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </Grid>
                {index !== teamMembers.length - 1 && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Team;
