import React, { useState, useEffect } from "react";
import "./check.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate,useLocation  } from "react-router-dom";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const Check = () => {
  const [d, setD] = useState({});
  const [s, setS] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const auth = searchParams.get('auth');




  useEffect(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/Check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ auth }),
      });
  
      const data = await response.json();
  
      if (data.status === 200) {
        setS(data.account);
        setD(data.data);
        setIsLoading(false);
      } else {
        navigate("/QrCheckPay");
      }
  
    } catch (error) {
      setIsLoading(false);
      toast.error('Error sending data to the API', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, []);
  
  const travelType =
  d.travel === 1 ? 'เดินเท้า' :
  d.travel === 2 ? 'ผู้ปกครองมารับ' :
  d.travel === 3 ? 'จักรยานยนต์' :
  'แสดงค่าไม่ได้';
  return (
    <div className="screen">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="rectangle" />
          <div className="text-wrapper" onClick={() => navigate("/QrCheckPay")}>ย้อนกลับ</div>
          <div className="div" />
          <div className="view">
            <div className="text-wrapper">จาก</div>
          </div>
          <div className="div-2">
            <img className="cat" alt="Cat" src="/img/v2_125.png" />
            <div className="text-wrapper-2">{s.acc_firstname} {s.acc_surname}</div>
            <div className="text-wrapper-3">{s.acc_username}</div>
            <div className="text-wrapper-4">ชั้น ม.{s.acc_class_room}</div>
          </div>
          <div className="view-2">
            <div className="text-wrapper-5">ตรวจสอบสำเร็จ</div>
            <p className="p">วันที่ : {d.date_create}</p>
            <div className="text-wrapper-6">รหัสอ้างอิง : {d.ref}</div>
            <div className="text-wrapper-7">{d.kyc ? 'ได้รับการยืนยันตัวตนแล้ว' : 'ยังไม่ได้ยืนยันตัวตน'}</div>
          </div>
          <div className="QR-code">
            <img src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=MTWIT.${auth}`} className="image" alt="Image" />
            <div className="text-wrapper-8">สแกน QRCODE นี้เพื่อตรวจสอบ</div>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group">
              <div className="text-wrapper-9">ตรวจสอบสำเร็จ</div>
              <img className="image-2" alt="Image" src="/img/v2_136.png" />
            </div>
          </div>
          <div className="view-3">
            <div className="overlap-2">
              <div className="text-wrapper-10">เหตุผล</div>
              <div className="text-wrapper-11">เวลา</div>
              <div className="text-wrapper-12">การเดินทาง</div>
              <div className="text-wrapper-13">OUT {d.time_out} IN {d.time_in}</div>
              <div className="text-wrapper-14">{d.reason}</div>
            </div>
            <div className="text-wrapper-15">{d.travel === "1" && 'เดินเท้า'} {d.travel === "2" && 'ผู้ปกครองมารับ'} {d.travel === "3" && 'จักรยานยนต์'}</div>
            <img className="image-3" alt="Image" src="/img/v3_139.png" />
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Check;