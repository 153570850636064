import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
  Grid,
  Avatar,
  TextField,
  MenuItem,
  Select,
  FormHelperText,
  InputLabel,
  FormControl,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import AppBarAdmin from "../../components/AppBarAdmin";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";

const AdminTeacherDetail = () => {
  const [detail, setDetail] = useState(null);
  const [user, setUser] = useState("");
  const [inputs, setInputs] = useState({
    acc_username: "",
    acc_surname: "",
    acc_firstname: "",
    acc_class_room: "",
    acc_role: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const acc_username = queryParams.get('acc_username');
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/teh_detail`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            acc_username: acc_username,
          }),
        });

        if (response.status === 200) {
          const data = await response.json();
          setDetail(data.detail);
        } else {
          console.error('เกิดข้อผิดพลาดในการดึงข้อมูลนักเรียน');
        }
      } catch (error) {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูลนักเรียน:', error);
      }
    };

    fetchData();
  }, [acc_username, token]);

  useEffect(() => {
    if (detail && detail.acc_id) {
      setInputs({
        acc_username: detail.acc_username,
        acc_full_name: detail.acc_full_name,
        acc_role: detail.acc_role,
        acc_surname: detail.acc_surname,
        acc_firstname: detail.acc_firstname,
        acc_class_room: detail.acc_class_room,
      });
    }
  }, [detail]);
  

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/user`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 200) {
          setUser(data.user);
        } else {
          localStorage.removeItem('token');
          navigate('/app/backend/login');
        }
      })
      .catch(error => {
        localStorage.removeItem('token');
        navigate('/app/backend/login');
        alert('คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง');
      });
  }, [token, navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      inputs.acc_firstname === '' ||
      inputs.acc_surname === '' ||
      inputs.acc_username === '' ||
      inputs.acc_class_room === '' ||
      inputs.acc_role === ''
    ) {
      toast.warn("กรุณากรอกข้อมลูให้ครบถ้วน", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/tehEdit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...inputs, // ส่งทั้งหมดจาก inputs state
          id: detail.acc_id, // เพิ่ม id ใน body
        }),
      });

      const result = await response.json();

      if (result.status === 200) {
        alert(result.message);
        navigate('/app/backend/allTeacher');
      } else {
        setIsLoading(false);
        alert(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('เกิดข้อผิดพลาดในการส่งคำขอ:', error);
    }
  };

  return (
    <>
      <AppBarAdmin />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            paddingTop: "35%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item xs>
              <Button
                variant="text"
                onClick={() => navigate("/app/backend/allTeacher")}
              >
                {"<< ย้อนกลับ"}
              </Button>
            </Grid>
          </Grid>
          {detail && (
            <>
              <Avatar sx={{ m: 1, bgcolor: "#80B7A2" }}>
                <InfoTwoToneIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                ข้อมลูของ {detail.acc_firstname} {detail.acc_surname}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      value={inputs.acc_firstname || ''}
                      name="acc_firstname"
                      onChange={handleChange}
                      fullWidth
                      label="ชื่อ"
                      InputLabelProps={{
                        style: { color: "#80B7A2" },
                      }}
                      sx={{
                        "& input": {
                          color: "#80B7A2",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      value={inputs.acc_surname || ''}
                      name="acc_surname"
                      onChange={handleChange}
                      fullWidth
                      label="สกุล"
                      InputLabelProps={{
                        style: { color: "#80B7A2" },
                      }}
                      sx={{
                        "& input": {
                          color: "#80B7A2",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="รหัสประจำตัว"
                      value={inputs.acc_username || ''}
                      name="acc_username"
                      onChange={handleChange}
                      autoComplete="family-name"
                      InputLabelProps={{
                        style: { color: "#80B7A2" },
                      }}
                      sx={{
                        "& input": {
                          color: "#80B7A2",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="มัธยมศึกษาปีที่"
                      value={inputs.acc_class_room || ''}
                      name="acc_class_room"
                      onChange={handleChange}
                      autoComplete="family-name"
                      InputLabelProps={{
                        style: { color: "#80B7A2" },
                      }}
                      sx={{
                        "& input": {
                          color: "#80B7A2",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel id="acc_role">สถานะครู</InputLabel>
                      <Select
                        labelId="acc_role"
                        sx={{ width: '45vh' }}
                        label="acc_role สถานะ"
                        onChange={handleChange}
                        name="acc_role"
                        value={inputs.acc_role || ''}
                      >
                        <MenuItem value={"teacher"}>ครูประจำชั่น</MenuItem>
                        <MenuItem value={"teacher_administrative"}>ครูประจำชั้น / ครูกิจการนักเรียน</MenuItem>
                      </Select>
                      <FormHelperText>สถานะตอนนี้ : {inputs.acc_role}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#80B7A2",
                    "&:hover": {
                      backgroundColor: "#64877a",
                    },
                  }}
                  type="submit"
                >
                  บันทึกการเปลี่ยนแปลง
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default AdminTeacherDetail;
