import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import Announce from "./Announce"; // ปรับเปลี่ยนเส้นทางไปยังไฟล์ App Bar ของคุณ

import { Navbar, NavbarCollapse, NavbarToggle } from "flowbite-react";

export default function MenuAppBarTeh() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = useState([]);
  const open = Boolean(anchorEl);
  const token = localStorage.getItem("token");
  const [backdrop, setBackdrop] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/teh/user`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        setUser(data.user);
        return;
      } else if (data.status === 403 || data.status === 401) {
        localStorage.removeItem("token");
        navigate("/app/teacher/teh-login");
        return;
      }
    };
    fetchData(); // เรียกใช้ฟังก์ชัน fetchData
  }, []);

  const sendMaintainRequest = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/maintain`,
        {
          method: "POST",
        }
      );
      const data = await response.json();
      if (data.status == 503) {
        navigate(`/app/Maintain`);
      }
    } catch (error) {
      // console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(sendMaintainRequest, 15000);
    return () => clearInterval(intervalId);
  }, []);

  const logout = () => {
    setBackdrop(true);
    setTimeout(() => {
      setBackdrop(false);
      localStorage.removeItem("token");
      localStorage.removeItem("isFirstLoad");
      localStorage.removeItem("acc_teh_username");
      localStorage.removeItem("acc_teh_password");
      toast.success("ออกจากระบบสำเร็จ");
      navigate("/app/teacher/teh-login");
    }, 2000);
  };

  return (
    <>
      <Announce />
      <Navbar fluid className="top-0 z-[999] dark:bg-white bg-white w-full">
        <div className="max-w-screen-xl flex bg-white flex-wrap items-center justify-between mx-auto p-4">
          <div
            href="#"
            onClick={() => navigate("/app/teacher/")}
            class="flex items-center space-x-3 bg-white mr-12"
          >
            <img
              src="/img/logomtwit-768x768 (3).png"
              class="h-8"
              alt="ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคม Logo"
            />
            <span class="self-center text-1xl font-semibold whitespace-nowrap">
              ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคม
            </span>
          </div>

          <NavbarToggle class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" />
          <NavbarCollapse>
            {token == null ? (
              <>
                <Navbar.Link onClick={() => navigate("/app/teacher/teh-login")}>
                  <div
                    href="#"
                    class="block py-2 px-3 text-pink-50 bg-pink-700 rounded p-0"
                  >
                    เข้าสู่ระบบครู
                  </div>
                </Navbar.Link>
                <Navbar.Link onClick={() => navigate("/app/backend/login")}>
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    เข้าสู่ระบบแอดมิน
                  </div>
                </Navbar.Link>
                <Navbar.Link>
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    {process.env.REACT_APP_V}
                  </div>
                </Navbar.Link>
              </>
            ) : (
              <>
                <Navbar.Link onClick={() => navigate("/app/teacher/profile")}>
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    <i class="bi bi-person-circle"></i> โปรไฟล์ และ ตั้งค่า
                  </div>
                </Navbar.Link>
                <Navbar.Link onClick={() => navigate("/app/teacher/stOut")}>
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    <i class="bi bi-file-plus"></i> คำขอออกนอกโรงเรียน
                    ของนักเรียน
                  </div>
                </Navbar.Link>
                <Navbar.Link
                  onClick={() => navigate("/app/teacher/allstudents")}
                >
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    <i class="bi bi-people"></i> นักเรียนของคุณ
                  </div>
                </Navbar.Link>
                <Navbar.Link
                  onClick={() => navigate("/app/teacher/outinfoAll")}
                >
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    <i class="bi bi-clock-history"></i>{" "}
                    นักเรียนที่ขอออกภายในวันนี้
                  </div>
                </Navbar.Link>
                <Navbar.Link onClick={logout}>
                  <div
                    href="#"
                    class="block py-2 px-3 text-pink-50 bg-pink-700 rounded p-0"
                  >
                    <i class="bi bi-box-arrow-left"></i> ออกจากระบบ
                  </div>
                </Navbar.Link>
              </>
            )}
          </NavbarCollapse>
        </div>
      </Navbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
