import React, { useState, useEffect } from "react";
import { Container, Button } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import AppBarTeh from "../../components/AppBarTeh";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { CssBaseline, Backdrop, CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";


const AllStudents = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [out, setOut] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [user, setUser] = useState({});

  const fetchData = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/teh/studentAll`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const formattedData = response.data.map((student, index) => ({
        ...student,
        id: student.acc_username,
      }));

      setOut(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/teh/user`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 200) {
          setUser(data.user);
        } else {
          localStorage.removeItem('token');
          navigate('/app/teacher/teh-login');
        } 
      })
      .catch(error => {
        localStorage.removeItem('token');
          navigate('/app/teacher/teh-login');
      });
  }, []);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatDateTime = (dateTime) => {
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric',
      timeZone: 'Asia/Bangkok'  // เปลี่ยน timeZone เป็น Asia/Bangkok
    };
    return dateTime.toLocaleString('th-TH', options);
  };


  const handleExportXLSX = async (event) => {
    event.preventDefault(); 
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/teh/studentAll`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // แปลงข้อมูลให้เหลือเฉพาะที่ต้องการ
      const filteredData = response.data.map(item => ({
        "รหัสประจำตัว": item.acc_username,
        "ชื่อ - สกุล": `${item.acc_firstname} ${item.acc_surname}`,
        "ออกนอกโรงเรียนสัปดาห์นี้": item.week_count,
        "ออกนอกโรงเรียนเดือนนี้": item.month_count,
        "ข้อมลูของวันที่" : formatDateTime(currentDateTime),
      }));
  
      // สร้าง Workbook
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(filteredData);
  
      // เพิ่ม Worksheet ลงใน Workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      // สร้างไฟล์ Excel
      XLSX.writeFile(wb, `ข้อมลูนักเรียนห้อง-${user.acc_class_room}-${formatDateTime(currentDateTime)}.xlsx`);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = [
    {
      field: "acc_username",
      headerName: "รหัสประจำตัว",
      width: 200,
    },
    {
        field: "id",
        headerName: "ชื่อ - สกุล",
        width: 200,
        valueGetter: (params) => {
          const { acc_firstname, acc_surname } = params.row;
          return `${acc_firstname} ${acc_surname}`;
        },
      },
    {
      field: "acc_class_room",
      headerName: "ห้อง",
      width: 100,
    },
    {
        field: "week_count",
        headerName: "ออกนอกโรงเรียนสัปดาห์นี้",
        width: 200,
    },
    {
        field: "month_count",
        headerName: "ออกนอกโรงเรียนเดือนนี้",
        width: 200,
    },
  ];

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <AppBarTeh />
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <CssBaseline />
        <h1>นักเรียนทั้งหมดของห้อง {user.acc_class_room}</h1>
        <Button
            fullWidth
            onClick={handleExportXLSX}
            variant="contained"
            disableElevation
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: "#ff699b",
              "&:hover": {
                backgroundColor: "#ff699b",
              },
            }}
          >
            Export XLSX
          </Button>
        <div style={{ height: 600, width: "100%" }}>
        <DataGrid
            columns={columns}
            rows={out}
            pagination
            onPageChange={(params) => handleChangePage(params.page)}
            onPageSizeChange={(params) => {
              setPage(0);
            }}
            pageSize={rowsPerPage}
            rowCount={out.length}
          />
        </div>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AllStudents;
