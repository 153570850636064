import React from 'react';
import { useNavigate } from 'react-router-dom'; // นำเข้า useNavigate แทน useHistory
import Button from '@mui/material/Button';

const Grade = () => {
  const navigate = useNavigate(); // เปลี่ยนการใช้ useHistory เป็น useNavigate

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '85vh', alignContent: 'center' }}>
      <div style={{ textAlign: 'center', alignItems: 'center', alignContent: 'center' }}>
        <h1>ขออภัยจากทีมผู้พัฒนา</h1>
        <p>เนื่องจากระบบเกรดยังมีปัญหาจึงยังไม่สามารถเปิดใช้งานได้ตอนนี้</p>
        <Button onClick={() => navigate(-1)} variant="text">ย้อนกลับ</Button> {/* ใช้ navigate(-1) เพื่อย้อนกลับไปหน้าก่อนหน้านี้ */}
      </div>
    </div>
  );
};

export default Grade;
