import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

export default function InactiveTime() {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const token = localStorage.getItem('token');


  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem('token')
    localStorage.removeItem('isFirstLoad');
    toast.error('กรุณาเข้าสู่ระบบใหม่ เนื่องจากเซลซั่นหมดอายุ');
    navigate(`/app/login?callbackUrl=${location.pathname}`)
  };


  const [inactiveTime, setInactiveTime] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/user`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      const data = await response.json();
      if (data.status === 403 || data.status === 401) {
        setOpen(true);
        return;
      }
      // ทำต่อไปตามความต้องการของคุณกับข้อมูลที่ได้รับ
    };

    const fetchDataAndSetInterval = () => {
      fetchData(); // เรียกใช้ fetchData ที่เริ่มต้น
      const intervalId = setInterval(fetchData, 6000); // 20 วินาที
      return () => clearInterval(intervalId);
    };

    fetchDataAndSetInterval();
  }, []);

  useEffect(() => {
    const handleInactive = () => {
      setInactiveTime(inactiveTime + 1);
      console.log(inactiveTime);
      if (inactiveTime >= 300) { // 10 นาที 
        setOpen(true);
      }
    };
  
    const resetInactiveTime = () => {
      setInactiveTime(0);
    };
  
    // ตั้งค่าการตรวจสอบเคลื่อนไหวทุก 1 นาที
    const checkInactiveInterval = setInterval(handleInactive, 1000); // 1 นาที = 60 วินาที
  
    // เมื่อมีการเคลื่อนไหวใหม่ รีเซ็ตเวลาไม่มีการเคลื่อนไหว
    document.addEventListener('mousemove', resetInactiveTime);
    document.addEventListener('keydown', resetInactiveTime);
  
    // ทำความสะอาดเมื่อคอมโพเนนต์ถูกยกเลิก
    return () => {
      clearInterval(checkInactiveInterval);
      document.removeEventListener('mousemove', resetInactiveTime);
      document.removeEventListener('keydown', resetInactiveTime);
    };
  }, [inactiveTime]);
  

  return (
    <React.Fragment>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Alert severity="error" onClose={handleClose}>
          <AlertTitle>เซลซั่นหมดอายุ</AlertTitle>
          คุณไม่ได้ทำรายการในเวลาที่กำหนด หรือ Tokenหมดอายุ เพื่อความปลอดภัยในการทำรายการ{' '}
          <strong>กรุณาเข้าสู่ระบบใหม่อีกครั้ง</strong>
        </Alert>
      </Dialog>
    </React.Fragment>
  );
}
