import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
  TextField,
  CircularProgress,
  Backdrop,
  Badge,
  Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AppBarTeh from "../../components/AppBarTeh";
import { styled } from '@mui/material/styles';
import { toast } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';



const ProfilePage = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const [backdrop, setBackdrop] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/teh/user`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
        } else if (data.status === 403 || data.status === 401) {
          localStorage.removeItem('token');
          navigate('/app/teacher/teh-login');
        } else if (data.status === 503) {
          localStorage.removeItem('token');
          navigate('/app/teacher/teh-login');
        }
        setLoading(false); // Set loading to false after fetching data
      })
      .catch((error) => {
        localStorage.removeItem('token');
        navigate('/app/teacher/teh-login');
      });
  }, []);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedUser((prevEditedUser) => ({
      ...prevEditedUser,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = async () => {
    setBackdrop(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/teh/change-password`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editedUser),
      });

      const data = await response.json();

      if (data.status === 200) {
        toast.success(data.message, {
          position: 'bottom-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setEditedUser({ oldPassword: '', newPassword: '', newPasswordConfirm: '' });
        setIsEditing(false);
      } else if (data.status === 401) {
        toast.error(data.message, {
          position: 'bottom-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setBackdrop(false);
    }
  };

  const generateLineLoginUrl = () => {
  
    const state = (
      Math.random().toString(36).substring(2) + Date.now() + Math.random()
    ).replace('.', '');
  
    const lineLoginUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENTID_LINE_TEH}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_REDIRECTURL_TEH)}&state=${state}&bot_prompt=aggressive&scope=profile%20openid`;
  
    return lineLoginUrl;
  };
  
  const handleLineLoginClick = () => {
    setBackdrop(true);
    setTimeout(() => {
      localStorage.setItem("acc_username", user.acc_username);
      const lineLoginUrl = generateLineLoginUrl();
      window.location.href = lineLoginUrl;
      setBackdrop(false);
    }, 1000);
  }

  function getClassRoomInfo(classRoomString) {
    if (classRoomString) {
      const classRoom = classRoomString.split('/');
      const classNumber = classRoom[0];
      const roomNumber = classRoom[1];
      return { classNumber, roomNumber };
    } else {
      return { classNumber: '', roomNumber: '' };
    }
  }
  
  const { classNumber, roomNumber } = getClassRoomInfo(user.acc_class_room);

  return (
    <div>
      <AppBarTeh/>
     {loading ? (
       <div className="hero min-h-screen flex items-center justify-center">
       <div className="hero-content text-center">
         <div className="max-w-md">
           <h1 className="text-3xl font-bold">กำลังเชื่อมต่อกับเซิฟเวอร์</h1>
           <div role="status">
             <svg
               aria-hidden="true"
               class="mt-4 inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
               viewBox="0 0 100 101"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <path
                 d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                 fill="currentColor"
               />
               <path
                 d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                 fill="currentFill"
               />
             </svg>
             <span class="sr-only">Loading...</span>
           </div>
           <div class="mb-1 text-base font-medium dark:text-white">กรุณารอสักครู่</div>
         </div>
       </div>
     </div>

        ) : (
      
      <div
        className="min-h-screen flex justify-center items-center p-8 text-gray-900"
      >
    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow">
        <div class="flex justify-end px-4 pt-4">
        <span class="bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300"><i class="bi bi-cloud-upload-fill"></i> {isEditing ? 'แก้ไขรหัสผ่าน' : 'โปรไฟล์'}</span>

        </div>
        <div class="flex flex-col items-center pb-10">
            <img class="w-24 h-24 mb-3 rounded-full shadow-lg" alt={user.acc_firstname} src={"/img/MTwit.jpg"} />
            <h5 class="mb-1 text-xl font-medium text-gray-900 ">{user.acc_firstname} {user.acc_surname}</h5>
            <span class="text-sm text-gray-500 ">รหัสประจำตัว {user.acc_username}</span>
            {user.acc_class_room && (
                  <>
                   <span class="text-sm text-gray-500 ">คุณครูประจำชั้นมัธยมศึกษาปีที่: {classNumber} ห้อง {roomNumber}</span>
                  </>
                )}
            {loading ? (
              <CircularProgress sx={{ color: '#ff699b' }} />
            ) : isEditing ? (
              <>
                <div class="mt-4 w-full lg:mx-20 mx-24 p-8">
                      <label className="form-control flex flex-col mt-2 ">
                      <div className="label mb-2">
                        <span className="label-text-alt">
                          รหัสผ่านเดิม
                        </span>
                      </div>
                      <input
                        type="password"
                        name='oldPassword'
                        placeholder="กรุณากรอกรหัสผ่านเก่า"
                        value={editedUser.oldPassword}
                        onChange={handleInputChange}
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        required
                      />
                    </label>

                    <label className="form-control flex flex-col mt-2 ">
                      <div className="label mb-2">
                        <span className="label-text-alt">
                        รหัสผ่านใหม่
                        </span>
                      </div>
                      <input
                        type="password"
                        name='newPassword'
                        placeholder="กรุณากรอกรหัสผ่านใหม่"
                        value={editedUser.newPassword}
                        onChange={handleInputChange}
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        required
                      />
                    </label>

                    <label className="form-control flex flex-col mt-2 ">
                      <div className="label mb-2">
                        <span className="label-text-alt">
                          ยืนยันรหัสผ่านใหม่
                        </span>
                      </div>
                      <input
                        type="password"
                        name='newPasswordConfirm'
                        placeholder="กรุณายืนยันรหัสผ่านใหม่"
                        value={editedUser.newPasswordConfirm}
                        onChange={handleInputChange}
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        required
                      />
                    </label>
                    <button
                    class="inline-flex items-center mt-3 px-4 py-2 text-sm font-medium text-center text-white bg-pink-700 rounded-lg hover:bg-pink-800 focus:ring-4 focus:outline-none focus:ring-pink-300"
                  onClick={handleSave}
                >
                  บันทึก
                </button>
                <button
                class="inline-flex items-center py-2 px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-pink-700 focus:z-10 focus:ring-4 focus:ring-gray-100 "
                  onClick={handleCancel}
                >
                  ยกเลิก
                </button>
                  </div>
              </>
            ) : (
              <>
             
            <div class="flex mt-4 md:mt-6">
            {user.acc_line_user_id === "0" ? (
               <div  onClick={handleLineLoginClick} class="inline-flex items-center py-2 px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-pink-700 focus:z-10 focus:ring-4 focus:ring-gray-100 ">ยังไม่ได้เชื่อมต่อการเข้าสู่ระบบกับ Line❗️</div>
            ) : (
              <div class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-pink-700 rounded-lg hover:bg-pink-800 focus:ring-4 focus:outline-none focus:ring-pink-300">เชื่อมต่อการเข้าสู่ระบบกับ Line แล้ว</div>
            )}
            </div>
            <div onClick={handleEdit} class="inline-flex items-center mt-3 px-4 py-2 text-sm font-medium text-center text-white bg-pink-700 rounded-lg hover:bg-pink-800 focus:ring-4 focus:outline-none focus:ring-pink-300"> {isEditing ? "บันทึกการเปลี่ยนแปลงรหัสผ่าน" : "เปลี่ยนรหัสผ่าน"}</div>

              </>
            )}

        </div>
    </div>

      </div>
      )}


    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ProfilePage;
