import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import AppBarAdmin from "../../components/AppBarAdmin";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  CssBaseline,
  Button,
  Backdrop,
  CircularProgress,
  TextField,
  Grid,
} from "@mui/material";
import * as XLSX from "xlsx";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Statistics = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [out, setOut] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/backend/statistics`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const formattedData = response.data.map((student, index) => ({
        ...student,
        id: student.acc_username,
      }));
      setLoading(false);
      setOut(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
        } else {
          localStorage.removeItem("token");
          navigate("/app/backend/login");
        }
      })
      .catch((error) => {
        localStorage.removeItem("token");
        navigate("/app/backend/login");
        alert(
          "คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        );
      });
  }, []);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatDateTime = (dateTime) => {
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric',
      timeZone: 'Asia/Bangkok'  // เปลี่ยน timeZone เป็น Asia/Bangkok
    };
    return dateTime.toLocaleString('th-TH', options);
  };


  const handleExportXLSX = async (event) => {
    event.preventDefault(); 
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/backend/statistics`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // แปลงข้อมูลให้เหลือเฉพาะที่ต้องการ
      const filteredData = response.data.map(item => ({
        "รหัสประจำตัวนักเรียน": item.acc_username,
        "ห้อง": item.acc_class_room,
        "ชื่อจริง": item.acc_firstname,
        "นามสกุล": item.acc_surname,
        "จำนวนการออก": item.num_out_of_school,
        "Date Export" : formatDateTime(currentDateTime),
      }));
  
      // สร้าง Workbook
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(filteredData);
  
      // เพิ่ม Worksheet ลงใน Workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      // สร้างไฟล์ Excel
      XLSX.writeFile(wb, `ข้อมลูนักเรียนที่ออกทั้งหมด-${formatDateTime(currentDateTime)}.xlsx`);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const columns = [
    {
      field: "acc_username",
      headerName: "รหัสประจำตัว",
      width: 200,
    },
    {
      field: "id",
      headerName: "ชื่อ - สกุล",
      width: 200,
      valueGetter: (params) => {
        const { acc_firstname, acc_surname } = params.row;
        return `${acc_firstname} ${acc_surname}`;
      },
    },
    {
      field: "acc_class_room",
      headerName: "ชั้นเรียน",
      width: 200,
    },
    {
      field: "num_out_of_school",
      headerName: "จำนวนที่ออก",
      width: 200,
    },
  ];

  return (
    <>
      <ToastContainer />
      <AppBarAdmin />
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <CssBaseline />
        <h1>สถิตการนักเรียนออกนอกโรงเรียนแม่ตื่นวิทยาคมเยอะที่สุด</h1>
        <div style={{ height: 750, width: "100%" }}>
          <Button
            fullWidth
            onClick={handleExportXLSX}
            variant="contained"
            disableElevation
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: "#80B7A2",
              "&:hover": {
                backgroundColor: "#637870",
              },
            }}
          >
            Export XLSX
          </Button>
          <DataGrid
            columns={columns}
            rows={out}
            pagination
            onPageChange={(params) => handleChangePage(params.page)}
            onPageSizeChange={(params) => {
              setPage(0);
            }}
            pageSize={rowsPerPage}
            rowCount={out.length}
          />
        </div>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Statistics;
