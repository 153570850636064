import React, { useState, useEffect } from "react";
import { Container, Button } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import AppBarTeh from "../../components/AppBarTeh";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { CssBaseline, Backdrop, CircularProgress } from "@mui/material";


const AllStudents = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [out, setOut] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [user, setUser] = useState({});

  const fetchData = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/teh/outinfoAll`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const formattedData = response.data.map((student, index) => ({
        ...student,
        id: student.acc_username,
      }));

      setOut(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/teh/user`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 200) {
          setUser(data.user);
        } else {
          localStorage.removeItem('token');
          navigate('/app/teacher/teh-login');
        } 
      })
      .catch(error => {
        localStorage.removeItem('token');
          navigate('/app/teacher/teh-login');
      });
  }, []);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const columns = [
    {
        field: "acc_username",
        headerName: "รหัสประจำตัว",
        width: 200,
      },
    {
      field: "reason",
      headerName: "เหตุผลการออก",
      width: 200,
    },
    {
        field: "all_status_thai",
        headerName: "สถานะ",
        width: 200,
    },
    {
        field: "time_out",
        headerName: "เวลาไป",
        width: 100,
    },
    {
        field: "time_in",
        headerName: "เวลากลับ",
        width: 100,
    },
    {
      field: "TEHDATA",
      headerName: "ชื่อครู",
      width: 200,
      valueGetter: (params) => {
        const { acc_firstname, acc_surname, acc_class_room } = params.row.TEHDATA;
        return `${acc_firstname} ${acc_surname} ${acc_class_room}`;
      },
    },
    {
      field: "STTDATA",
      headerName: "ชื่อนักเรียน",
      width: 200,
      valueGetter: (params) => {
        const { acc_firstname, acc_surname, acc_class_room } = params.row.STTDATA;
        return `${acc_firstname} ${acc_surname} ${acc_class_room}`;
      },
    },
  ];
  

  const [open, setOpen] = React.useState(false);


  // สร้าง state สำหรับเก็บข้อมูลวันที่
  const [currentDate, setCurrentDate] = useState(new Date());

  // useEffect เพื่ออัปเดต state เมื่อ component โหลด
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000); // อัปเดตทุก 1 วินาที

    // คืน function ที่จะทำการ clear interval เมื่อ component ถูก unmount
    return () => clearInterval(intervalId);
  }, []); // ให้ useEffect ทำงานเพียงครั้งเดียวเมื่อ component โหลด

  return (
    <>
      <AppBarTeh />
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <CssBaseline />
        <h1>นักเรียนทั้งหมดที่ขออกนอกใน วันที่ {currentDate.toLocaleString()}</h1>
        <div style={{ height: 600, width: "100%" }}>
        <DataGrid
            columns={columns}
            rows={out}
            pagination
            onPageChange={(params) => handleChangePage(params.page)}
            onPageSizeChange={(params) => {
              setPage(0);
            }}
            pageSize={rowsPerPage}
            rowCount={out.length}
          />
        </div>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AllStudents;
