import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MaintenancePage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isMaintaining, setIsMaintaining] = useState(true);

  const sendMaintainRequest = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/maintain`,
        {
          method: "POST",
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        setIsMaintaining(false);
        setOpen(false);
        navigate("/");
      } else if (data.status === 503) {
        setIsMaintaining(data);
        setOpen(true);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(sendMaintainRequest, 1500);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <React.Fragment>
        <Dialog fullScreen open={open} TransitionComponent={Transition}>
          {isMaintaining ? (
            <>
              <AppBar sx={{ backgroundColor: "#ec407a", position: "relative" }}>
                <Toolbar>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    ปิดปรับปรุง MTwit Service's และ MTwit Social's
                  </Typography>
                </Toolbar>
              </AppBar>

              <Avatar
                src={isMaintaining.img}
                variant="rounded"
                sx={{
                  width: 100,
                  height: "auto",
                  alignSelf: "center",
                  marginTop: 5,
                }}
              ></Avatar>
              <Typography
                sx={{ textAlign: "center" }}
                variant="h6"
                gutterBottom
                p={4}
              >
                {isMaintaining && isMaintaining.message ? (
                  isMaintaining.message.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))
                ) : (
                  <CircularProgress />
                )}
              </Typography>
            </>
          ) : (
            <Typography variant="h5" gutterBottom>
              Maintenance Completed
            </Typography>
          )}
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default MaintenancePage;
