import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
  Grid,
  Avatar,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import AppBarAdmin from "../../components/AppBarAdmin";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";

const AdminSetting = () => {
  const [appSetting, setAppSetting] = useState(null);
  const [user, setUser] = useState("");
  const [inputs, setInputs] = useState({
    setting_maintain_message: "",
    setting_maintain_img: "",
    setting_maintain_status: "",
    setting_announce_message: "",
    setting_announce_img: "",
    setting_announce_status: "",
  });

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/backend/appSetting`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setAppSetting(data);
      } else {
        console.error("เกิดข้อผิดพลาดในการดึงข้อมูลนักเรียน");
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการดึงข้อมูลนักเรียน:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  useEffect(() => {
    if (appSetting) {
      setInputs({
        setting_maintain_message: appSetting.setting_maintain_message,
        setting_maintain_img: appSetting.setting_maintain_img,
        setting_maintain_status: appSetting.setting_maintain_status,
        setting_announce_message: appSetting.setting_announce_message,
        setting_announce_img: appSetting.setting_announce_img,
        setting_announce_status: appSetting.setting_announce_status,
      });
    }
  }, [appSetting]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
        } else {
          localStorage.removeItem("token");
          navigate("/app/backend/login");
        }
      })
      .catch((error) => {
        localStorage.removeItem("token");
        navigate("/app/backend/login");
        alert(
          "คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        );
      });
  }, [token, navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handleEditAnnounce = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/backend/editAnnounce`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...inputs, // ส่งทั้งหมดจาก inputs state
          }),
        }
      );
      const result = await response.json();
      if (result.status === 200) {
        toast.success(result.message);
        // fetchData();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการบันทึกการเปลี่ยนแปลง:", error);
    }
  };

  const handleEditMaintain = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/backend/editMaintain`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...inputs, // ส่งทั้งหมดจาก inputs state
          }),
        }
      );
      const result = await response.json();
      if (result.status === 200) {
        toast.success(result.message);
        // fetchData();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการบันทึกการเปลี่ยนแปลง:", error);
    }
  };

  return (
    <>
      <AppBarAdmin />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            paddingTop: "35%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <>
            <Avatar sx={{ m: 1, bgcolor: "#80B7A2" }}>
              <InfoTwoToneIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              ตั้งค่าระบบ
            </Typography>
            <Box noValidate sx={{ mt: 3 }}>
              <Typography variant="h6">ระบบการแจ้งเตือน</Typography>
              <form onSubmit={handleEditAnnounce}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline-static"
                      placeholder="ข้อความประกาศ"
                      name="setting_announce_message"
                      onChange={handleChange}
                      multiline
                      fullWidth
                      rows={4}
                      defaultValue={inputs.setting_announce_message || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="given-name"
                      value={inputs.setting_announce_img || ""}
                      name="setting_announce_img"
                      onChange={handleChange}
                      fullWidth
                      label="ลิงค์รุปภาพประกาศ"
                      InputLabelProps={{
                        style: { color: "#80B7A2" },
                      }}
                      sx={{
                        "& input": {
                          color: "#80B7A2",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel id="setting_announce_status">
                        สถานะ การเปิดใช้งาน
                      </InputLabel>
                      <Select
                        labelId="setting_announce_status"
                        sx={{ width: "45vh" }}
                        fullWidth
                        onChange={handleChange}
                        name="setting_announce_status"
                        value={inputs.setting_announce_status || ""}
                      >
                        <MenuItem value={"true"}>เปิด</MenuItem>
                        <MenuItem value={"false"}>ปิด</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        backgroundColor: "#80B7A2",
                        "&:hover": {
                          backgroundColor: "#64877a",
                        },
                      }}
                      type="submit"
                    >
                      บันทึกการเปลี่ยนแปลง
                    </Button>
                  </Grid>
                </Grid>
              </form>

              <Typography variant="h6" mt={3}>
                ระบบการปิดปรับปรุ่ง
              </Typography>
              <form onSubmit={handleEditMaintain}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-maintain-static"
                      placeholder="ข้อความปิดปรับปรุ่ง"
                      name="setting_maintain_message"
                      onChange={handleChange}
                      multiline
                      fullWidth
                      rows={4}
                      defaultValue={inputs.setting_maintain_message || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="given-name"
                      value={inputs.setting_maintain_img || ""}
                      name="setting_maintain_img"
                      onChange={handleChange}
                      fullWidth
                      label="ลิงค์รูปภาพปิดปรับปรุ่ง"
                      InputLabelProps={{
                        style: { color: "#80B7A2" },
                      }}
                      sx={{
                        "& input": {
                          color: "#80B7A2",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel id="setting_maintain_status">
                        สถานะ การเปิดใช้งาน
                      </InputLabel>
                      <Select
                        labelId="setting_maintain_status"
                        sx={{ width: "45vh" }}
                        fullWidth
                        onChange={handleChange}
                        name="setting_maintain_status"
                        value={inputs.setting_maintain_status || ""}
                      >
                        <MenuItem value={"true"}>เปิด</MenuItem>
                        <MenuItem value={"false"}>ปิด</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        backgroundColor: "#80B7A2",
                        "&:hover": {
                          backgroundColor: "#64877a",
                        },
                      }}
                      type="submit"
                    >
                      บันทึกการเปลี่ยนแปลง
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </>
        </Box>
      </Container>
    </>
  );
};

export default AdminSetting;
