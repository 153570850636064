import React, { useState } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { toast, ToastContainer } from "react-toastify";
import MenuAppBar from "../components/AppBar"; // ปรับเปลี่ยนเส้นทางไปยังไฟล์ App Bar ของคุณ
import "react-toastify/dist/ReactToastify.css";

import { Container, Box } from "@mui/material";
function App() {
  const [qrCode, setQrCode] = useState("");

  const handleScan = (data) => {
    if (data) {
      setQrCode(data);
      const inputString = qrCode;
      const delimiterIndex = inputString.indexOf(".");
      if (delimiterIndex !== -1) {
        const part1 = inputString.substring(0, delimiterIndex);
        const part2 = inputString.substring(delimiterIndex + 1);
        console.log("รหัสส่ง:", part1);
        console.log("ค่าที่ส่ง:", part2);
      } else {
        toast.error("ไม่สามารถใช้ QR CODE นี้ได้");
      }
      // toast.success(data);
    }
  };

  const handleError = (error) => {
    console.log(error?.message);
  };

  return (
    <>
      <MenuAppBar />
      <ToastContainer />
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h1>สแกน คิวอาร์โค้ด เช็ค</h1>
        <Box width={"50vh"}>
          <QrScanner onDecode={handleScan} onError={handleError} />
        </Box>

        {qrCode && <p>QR Code: {qrCode}</p>}
      </Container>
    </>
  );
}

export default App;
