import React from "react";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="max-w-screen-xl flex bg-white flex-wrap items-center justify-between mx-auto p-4">
        <div href="#" class="flex items-center space-x-3 bg-white mr-12">
          <img
            src="/img/logomtwit-768x768 (3).png"
            class="h-8"
            alt="ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคม Logo"
          />
          <span class="self-center text-1xl font-semibold whitespace-nowrap">
            ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคม
          </span>
          <h1 class="text-1xl font-extrabold MTwit_Home">5.3</h1>
        </div>
      </div>

      <section class="bg-white">
        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">
            <img
              src="/img/home1.jpeg"
              class="max-w-36"
              alt="mt logo"
              data-aos="fade-up"
              data-aos-delay="800"
            />
            <div class="flex items-center">
              <h1
                class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl mr-2"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                MTwit Service
              </h1>
              <h1 class="text-8xl font-extrabold MTwit_Home">5.3</h1>
            </div>

            <p
              class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              Web application
              ออกนอกโรงเรียนแม่ตื่นวิทยาคมหรือมากกว่าออกนอกโรงเรียน
              ที่พัฒนาจากนักเรียน
            </p>
            <div
              data-aos="fade-up"
              data-aos-delay="600"
              onClick={() => navigate("/app/login")}
              class="inline-flex items-center justify-center px-5 py-3 mr-3 text-gray-900 font-medium text-center  rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 "
            >
              เข้าสู่ระบบ
              <svg
                class="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <a
              href="#about"
              data-aos="fade-up"
              data-aos-delay="700"
              class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 "
            >
              เกี่ยวกับระบบ
            </a>
          </div>
          <div
            class="lg:mt-0 lg:col-span-5 lg:flex mt-4"
            data-aos="zoom-out-up"
            data-aos-delay="900"
          >
            <div class="relative mx-auto border-gray-800  bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
              <div class="w-[100px] h-[20px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
              <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
              <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
              <div class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
              <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white ">
                <img
                  src="/img/IMG_D43624AA9275-1.jpeg"
                  class="w-[272px] h-[572px]"
                  alt="home"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-white" id="about">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
            <h2
              class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              Co-Founder
            </h2>
            <p
              class="font-light text-gray-500 sm:text-xl"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              ผู้ก่อตั้ง MTwit Service
              ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคมหรือเป็นมากกว่าออกนอกโรงเรียน
            </p>
          </div>
          <div class="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <div
              class="text-center text-gray-500"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img
                class="mx-auto mb-1"
                src="/img/IMG_5500-removebg-preview.png"
                alt="Sittichai Manorat"
              />
              <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                Sittichai Manorat
              </h3>
              <p>Co-founder / Make documents</p>
              <ul class="flex justify-center mt-4 space-x-4">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100089137626906"
                    class="text-[#39569c] hover:text-gray-900"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            <div
              class="text-center text-gray-500"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <img
                class="mx-auto mb-1"
                src="/img/IMG_5073-removebg-preview.png"
                alt="Nattawat Thakham"
              />
              <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                Nattawat Thakham
              </h3>
              <p>Co-founder / Make documents</p>
              <ul class="flex justify-center mt-4 space-x-4">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100014820363162"
                    class="text-[#39569c] hover:text-gray-900"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            <div
              class="text-center text-gray-500"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <img
                class="mx-auto mb-1"
                src="/img/IMG_5494_2-removebg-preview.png"
                alt="Teerapat Tawang"
              />
              <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                Teerapat Tawang
              </h3>
              <p>Co-founder / Make a system (Developer)</p>
              <ul class="flex justify-center mt-4 space-x-4">
                <li>
                  <a
                    href="https://www.facebook.com/Theerapat.me/"
                    class="text-[#39569c] hover:text-gray-900"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            <div
              class="text-center text-gray-500"
              data-aos="fade-up"
              data-aos-delay="800"
            >
              <img
                class="mx-auto mb-1"
                src="/img/IMG_6647_2-removebg-preview.png"
                alt="GibZy Kasinee"
              />
              <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                GibZy Kasinee
              </h3>
              <p>Co-founder / Coordinate / Prepare documents</p>
              <ul class="flex justify-center mt-4 space-x-4">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100000037790042"
                    class="text-[#39569c] hover:text-gray-900"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-white">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
            <h2
              class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900"
              data-aos="fade-up"
              data-aos-delay="900"
            >
              The advantages provided
            </h2>
            <p
              class="font-light text-gray-500 sm:text-xl"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              ข้อดีของ MTwit Service
              ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคมหรือเป็นมากกว่าออกนอกโรงเรียน
            </p>
          </div>
          <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
            <dl
              class="grid max-w-screen-md gap-8 mx-auto text-gray-900 sm:grid-cols-3 dark:text-white"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="flex flex-col items-center justify-center">
                <dt class="mb-2 text-3xl md:text-4xl font-extrabold">98%</dt>
                <dd class="font-light text-gray-500 dark:text-gray-400">
                  การออกแบบถูกกฏภายในโรงเรียน
                </dd>
              </div>
              <div
                class="flex flex-col items-center justify-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <dt class="mb-2 text-3xl md:text-4xl font-extrabold">99%</dt>
                <dd class="font-light text-gray-500 dark:text-gray-400">
                  การกลับมาจากออก
                </dd>
              </div>
              <div
                class="flex flex-col items-center justify-center"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <dt class="mb-2 text-3xl md:text-4xl font-extrabold">99%</dt>
                <dd class="font-light text-gray-500 dark:text-gray-400">
                  การยืนยันตัวตนหลังกลับ
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>

      <section class="bg-white">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
            <h2
              class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900"
              data-aos="fade-up"
              data-aos-delay="900"
            >
              Why did you make this system?
            </h2>
            <p
              class="font-light text-gray-500 sm:text-xl"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              ทำไมถึงทำระบบ MTwit Service
              ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคมหรือเป็นมากกว่าออกนอกโรงเรียน นี้
              ?
            </p>
          </div>

          <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
            <img
              class="w-full"
              src="/img/mtev/IMG_6595.JPG"
              alt="mtev"
              data-aos="fade-right"
            />
            <div class="mt-4 md:mt-0">
              <h2
                class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                Sittichai Manorat said.
              </h2>
              <p
                class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                ความคิดใหม่ที่เรามีคือเราไม่คาดหวังว่าจะใช้เองเลยครับ
                เพราะส่วนใหญ่ผมไม่ได้ออกนอกโรงเรียนบ่อย
                แต่ถ้าคุณออกไปแล้วเกิดอุบัติเหตุ เราคิดว่าโรงเรียนจะรับผิดชอบไหม
                ถ้าคุณไม่ได้รับการรับผิดชอบจากใคร คุณจะไม่ถูกตามกฎหมายใช่ไหม
                และมันจะช่วยเพื่อคนอื่นที่ต้องกลับบ้านได้สะดวกมากกว่าการอยู่ในห้องเรียนและตอบคำถามของครูที่ยุ่งยากอยู่ตลอดเวลา
              </p>
            </div>
          </div>

          <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
            <div class="mt-4 md:mt-0">
              <h2
                class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                Teerapat Tawang said.
              </h2>
              <p
                class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                คือที่เราจัดทำระบบนี้เพื่อความสะดวกส่วนตัวเป็นคนที่ออกนอกโรงเรียนอยู่แล้วแล้วค่อนข้างที่จะหาครูยาก
                หลังจากมีระบบนี้เข้ามาก็สะดวกขึ้นกว่าเดิมโดยที่แค่สร้างคำขอแล้วรอครูอนุมัติ
              </p>
            </div>
            <img
              class="w-full"
              src="/img/mtev/IMG_6596.JPG"
              alt="mtev"
              data-aos="fade-right"
            />
          </div>
        </div>
      </section>

      <section class="bg-white">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
            <h2
              class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900"
              data-aos="fade-up"
              data-aos-delay="900"
            >
              Sponsor
            </h2>
            <p
              class="font-light text-gray-500 sm:text-xl"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              ผู้สนับสนุน MTwit Service
              ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคมหรือเป็นมากกว่าออกนอกโรงเรียน นี้
            </p>
          </div>

          <div class="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <div
              class="text-center text-gray-500"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img
                class="mx-auto mb-1 rounded-md"
                src="/img/Logo_bg.jpg"
                alt="Hnawny Cloud"
              />
              <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                Hnawny Cloud
              </h3>
              <p>Allocate servers, staff, and maintain the system.</p>
              <ul class="flex justify-center mt-4 space-x-4">
                <li>
                  <a
                    href="https://hnawny.in.th/"
                    class="text-[#ff5468] hover:text-gray-900"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="currentColor"
                      class="bi bi-browser-chrome"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16 8a8 8 0 0 1-7.022 7.94l1.902-7.098a3 3 0 0 0 .05-1.492A3 3 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8M0 8a8 8 0 0 0 7.927 8l1.426-5.321a3 3 0 0 1-.723.255 3 3 0 0 1-1.743-.147 3 3 0 0 1-1.043-.7L.633 4.876A8 8 0 0 0 0 8m5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a3 3 0 0 0-1.252.243 2.99 2.99 0 0 0-1.81 2.59M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            <div
              class="text-center text-gray-500"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <img
                class="mx-auto mb-1 rounded-md"
                src="/img/1.jpeg"
                alt="Hnawny Cloud"
              />
              <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                Lama ไลมา
              </h3>
              <p>Allocate servers, staff, and maintain the system.</p>
              <ul class="flex justify-center mt-4 space-x-4">
                <li>
                  <a
                    href="https://lama.app/"
                    class="text-[#ff5468] hover:text-gray-900"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="currentColor"
                      class="bi bi-browser-chrome"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16 8a8 8 0 0 1-7.022 7.94l1.902-7.098a3 3 0 0 0 .05-1.492A3 3 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8M0 8a8 8 0 0 0 7.927 8l1.426-5.321a3 3 0 0 1-.723.255 3 3 0 0 1-1.743-.147 3 3 0 0 1-1.043-.7L.633 4.876A8 8 0 0 0 0 8m5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a3 3 0 0 0-1.252.243 2.99 2.99 0 0 0-1.81 2.59M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            <div
              class="text-center text-gray-500"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <img
                class="mx-auto mb-1 rounded-md"
                src="/img/TermgameSI.jpeg"
                alt="Hnawny Cloud"
              />
              <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                เติมเกมสิ
              </h3>
              <p>Allocate servers, staff, and maintain the system.</p>
              <ul class="flex justify-center mt-4 space-x-4">
                <li>
                  <a
                    href="https://termgamesi.app/"
                    class="text-[#ff5468] hover:text-gray-900"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="currentColor"
                      class="bi bi-browser-chrome"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16 8a8 8 0 0 1-7.022 7.94l1.902-7.098a3 3 0 0 0 .05-1.492A3 3 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8M0 8a8 8 0 0 0 7.927 8l1.426-5.321a3 3 0 0 1-.723.255 3 3 0 0 1-1.743-.147 3 3 0 0 1-1.043-.7L.633 4.876A8 8 0 0 0 0 8m5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a3 3 0 0 0-1.252.243 2.99 2.99 0 0 0-1.81 2.59M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
