import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LineLogin = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [backdrop, setBackdrop] = useState(false);
  const code = searchParams.get('code');
  const token = localStorage.getItem('token');



  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/teh/LineCheck`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`,
        },
        body: JSON.stringify({ code}),
      });
      const data = await response.json();
      if (data.status === 200) {
        setBackdrop(true);
        navigate('/app/teacher/profile');
      } else {
        alert(data.message);
        navigate('/app/teacher/profile');
      }
    };
    fetchData();
  }, [code, navigate]);



  return (
    <>
      <ToastContainer />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            paddingTop: '55%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#ec407a' }}>
            <LockOutlinedIcon />
          </Avatar>
          <h1>เชื่อมต่อกับ Line</h1>
          <p>ส่งคำขอเชื่อมต่อ Line สำเร็จ โปรดรอ...</p>
          <p>Code : {code} To Acc Token : {token}</p>
        </Box>
      </Container>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default LineLogin;
