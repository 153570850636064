import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from '@mui/material/ListItemIcon';

import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from '@mui/material/Select';



import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import SleddingTwoToneIcon from '@mui/icons-material/SleddingTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import AutorenewTwoToneIcon from '@mui/icons-material/AutorenewTwoTone';

import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import PauseCircleFilledTwoToneIcon from '@mui/icons-material/PauseCircleFilledTwoTone';

export default function MenuAppBar() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = useState([]);
  const [open, setOpen] = React.useState(false);
  const token = localStorage.getItem('token_qa');
  const [backdrop, setBackdrop] = React.useState(false);
  const room_ref = localStorage.getItem("room_ref");


  const location = useLocation();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReroom = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/q/a/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: room_ref }),
      });
      const data = await response.json();
      if (data.status === 200) {
        console.log("รีห้องสำเร็จ");
        localStorage.setItem("DataRoom", JSON.stringify(data.dataRoom));
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error(error);
    }


    //
    try {
      const response_q = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/q/a/get_qa_question`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ code: room_ref }),
      });
      const data_q = await response_q.json();
      if (data_q.status === 200) {
        console.log(`Get Question Successfully ${data_q}`);
      } else {
        alert(data_q.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const logout = () => {
    setBackdrop(true);
    setTimeout(() => {
      setBackdrop(false);
      localStorage.removeItem('token_qa');
      localStorage.removeItem('room_ref');
      localStorage.removeItem('DataRoom');
      localStorage.removeItem('time_remaining');
      localStorage.removeItem('code');
      toast.success('ออกจากห้องสำเร็จ');
      navigate(`/app/QA/home`);
    }, 2000);
  };


  const [audio] = useState(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [currentSongName, setCurrentSongName] = useState('');

  const songs = [
    { name: 'Moshi Moshi Long', url: '/song/MoshiMoshiLong.mp3' },
    { name: 'SEQUÊNCIA', url: '/song/SEQUÊNCIA.mp3' },
    { name: 'Sunflower', url: '/song/sunflower.mp3' },
    { name: 'New Thang', url: '/song/NewThang.mp3' },
    { name: 'Shall we', url: '/song/Shallwe.mp3' },
    { name: 'Hotline Bling X ريمكس - شيرين - إيه إيه ', url: '/song/HotlineBling.mp3' },
    { name: 'N33d you', url: '/song/N33dyou.mp3' },
  ];

  const playPauseHandler = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.src = songs[currentSongIndex].url;
      audio.play().then(() => {
        setIsPlaying(true);
        setCurrentSongName(songs[currentSongIndex].name);
      }).catch((error) => {
        console.error('Error playing audio:', error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    audio.addEventListener('play', handlePlay);
    audio.addEventListener('pause', handlePause);

    return () => {
      audio.removeEventListener('play', handlePlay);
      audio.removeEventListener('pause', handlePause);
    };
  }, [audio]);

  const handleSongChange = (event) => {
    const newIndex = parseInt(event.target.value, 10);
    setCurrentSongIndex(newIndex);
  };

  return (
    <>
      <ToastContainer />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ backgroundColor: '#8700ef' }}>
          <Toolbar>
            <Typography
              variant="subtitle1"
              // onClick={() => navigate('/app/QA/home')}
              sx={{ flexGrow: 1 }}
            >
              <h4>MTwit Service : Q&A</h4>
            </Typography>

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {token == null ? (
                <MenuRoundedIcon />
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    alt={user.acc_firstname}
                    src={'https://xsgames.co/randomusers/avatar.php?g=pixel'}
                    sx={{ width: 40, height: 40, margin: '0 auto' }}
                  />
                </div>
              )}
            </IconButton>

            <Menu
              id="menu-user"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuList dense sx={{ width: 300 }}>
              <MenuItem>
                  <ListItemText>
                  <h6>{currentSongName}</h6>
                    <Select
                      size="small"
                      fullWidth
                      label="Select Song"
                      value={currentSongIndex}
                      onChange={handleSongChange}
                    >
                      {songs.map((song, index) => (
                        <MenuItem key={index} value={index}>{song.name}</MenuItem>
                      ))}
                    </Select>
                  </ListItemText>
                </MenuItem>

                <MenuItem onClick={playPauseHandler}>
                      <ListItemIcon>
                      {isPlaying ? 
                      <PauseCircleFilledTwoToneIcon sx={{ color: '#8700ef' }} fontSize="small" />
                       : 
                       <PlayCircleTwoToneIcon sx={{ color: '#8700ef' }} fontSize="small" />
                      }
                        
                      </ListItemIcon>
                  <ListItemText>{isPlaying ? 'หยุด' : 'เล่น'}</ListItemText>
                </MenuItem>
                {token == null ? (
                  [
                    <Divider key="divider1" />,
                    <MenuItem
                      key="student-login"
                      onClick={() => navigate('/app/Q&A/createroom')}
                    >
                      <ListItemIcon>
                        <AddCircleTwoToneIcon sx={{ color: '#8700ef' }} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>สร้างห้อง</ListItemText>
                    </MenuItem>,
                    <MenuItem key="teacher-login" onClick={() => navigate('/app/Q&A/home')}>
                      <ListItemIcon>
                        <SleddingTwoToneIcon sx={{ color: '#8700ef' }} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>เข้าห้อง</ListItemText>
                    </MenuItem>,
                  ]
                ) : (
                  [
                    <Divider key="divider1" />,
                    <MenuItem onClick={handleReroom}>
                      <ListItemIcon>
                        <AutorenewTwoToneIcon sx={{ color: '#8700ef' }} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>รีห้อง</ListItemText>
                    </MenuItem>,
                    <Divider key="divider2" />,
                    <MenuItem onClick={logout}>
                      <ListItemIcon>
                        <CancelPresentationTwoToneIcon sx={{ color: '#8700ef' }} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>ออกจากห้อง</ListItemText>
                    </MenuItem>,
                  ]
                )}
              </MenuList>
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
