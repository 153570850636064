import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  CardContent,
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
  Grid,
  Card,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@mui/material';

import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

import AppBarTeh from "../../components/AppBarTeh";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HistoryOutDetailTeacher = () => {
  const [detail, setDetail] = useState(null);
  const [user, setUser] = useState('');
  const [selectedClassInstructor, setSelectedClassInstructor] = useState(0);
  const [selectedStudentAffairsTeacher, setSelectedStudentAffairsTeacher] = useState(0);
  const { id } = useParams();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const isClassInstructorEnabled = user.acc_class_room === detail?.acc_class_room;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/teh/history_out_detail`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: id,
          }),
        });

        if (response.status === 200) {
          const data = await response.json();
          setDetail(data.detail);
          // Check for empty values and set initial values for selectedClassInstructor and selectedStudentAffairsTeacher
          setSelectedClassInstructor(data.detail.class_instructor || 0);
          setSelectedStudentAffairsTeacher(data.detail.student_affairs_teacher || 0);
        }
      } catch (error) {
        // Handle error or navigate to login
      }
    };

    fetchData();
  }, [id, token, navigate]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/teh/user`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
        } else if (
          data.status === 403 ||
          data.status === 401 ||
          data.status === 503
        ) {
          // Handle error or navigate to login
        }
      })
      .catch((error) => {
        // Handle error or display an alert
      });
  }, [token, navigate]);

  const handleSaveChanges = async () => {
    const requestData = {
      id: id,
      classInstructorStatus: selectedClassInstructor,
      studentAffairsTeacherStatus: selectedStudentAffairsTeacher,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/teh/save_out_student`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.status === 200) {
        const data = await response.json();
        toast.success(data.message, {
          position: 'bottom-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        // ทำงานเพิ่มเติมหลังบันทึกสำเร็จ (เช่น, รีเซ็ตค่าหรือปิดหน้าการแก้ไข)
      } else if (response.status === 401) {
        const data = await response.json();
        toast.error(data.message, {
          position: 'bottom-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        // จัดการกรณีอื่น ๆ ที่เป็นไปได้ (เช่น, แสดงข้อความข้อผิดพลาด)
        toast.error('มีข้อผิดพลาดในการบันทึกการเปลี่ยนแปลง', {
          position: 'bottom-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      // จัดการข้อผิดพลาดในการส่งคำขอหรือตอบสนองจากเซิร์ฟเวอร์
      console.error('เกิดข้อผิดพลาดในการส่งคำขอหรือรับข้อมูล:', error);
      toast.error('เกิดข้อผิดพลาดในการส่งคำขอหรือรับข้อมูล', {
        position: 'bottom-center',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  

  return (
    <>
      <AppBarTeh />
      <ToastContainer />
      {detail ? (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            paddingTop: "35%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#f25cc0" }}>
            <InfoTwoToneIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            รายละเอียดขอผู้ขออนุญาต  #{id}
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
              <div className="label mb-2">
                <span className="label-text-alt">
                  ชื่อ - นามสกุล
                </span>
              </div>
                <input
                  value={`${detail.user.acc_firstname} ${detail.user.acc_surname}`}
                  className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <div className="label mb-2">
                <span className="label-text-alt">
                 รหัสประจำตัว
                </span>
              </div>
                <input
                  className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                  value={detail.user.acc_username}
                />
              </Grid>
              <Grid item xs={12} >
              <div className="label mb-2">
                <span className="label-text-alt">
                  ห้อง
                </span>
              </div>
                <input
                  className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                  value={detail.user.acc_class_room}
                />
              </Grid>
              <Grid item xs={12} >
                <Typography component="h1" variant="h5">
                ข้อมูลการขอออกไปนอกโรงเรียน
                </Typography>
              </Grid>
              <Grid item xs={12}>
              <div className="label mb-2">
                <span className="label-text-alt">
                 เหตุผล
                </span>
              </div>
              <input
                  className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                  value={detail.reason}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <div className="label mb-2">
                <span className="label-text-alt">
                  เวลาออก
                </span>
              </div>
                <input
                  className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                  value={`${detail.time_out} น.`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <div className="label mb-2">
                <span className="label-text-alt">
                  เวลากลับ
                </span>
              </div>
                <input
                  className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                  value={`${detail.time_in} น.`}
                />
              </Grid>
              <Grid item xs={12}>
              <div className="label mb-2">
                <span className="label-text-alt">
                  วันเวลาที่สร้างคำขอ
                </span>
              </div>
                <input
                  className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                  value={`${detail.date_create}`}
                />
              </Grid>
              <Grid item xs={12}>
              <div className="label mb-2">
                <span className="label-text-alt">
                  การเดินทาง
                </span>
              </div>
              <input
                  value={detail.travel}
                  className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                />
              </Grid>

              <Grid item xs={12}>
              {user.acc_role === 'teacher_administrative' && (
                  <FormControl sx={{ m: 1}} fullWidth>
                      <label id="student-affairs-teacher-label">สถานะครูกิจการนักเรียน</label>
                      <select
                      labelId="student-affairs-teacher-label"
                      value={selectedStudentAffairsTeacher}
                      onChange={(e) => setSelectedStudentAffairsTeacher(e.target.value)}
                      // sx={{ width: '40vh' }}
                      className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                      label="สถานะครูกิจการนักเรียน"
                      >
                          <option value={1} selected={selectedStudentAffairsTeacher === 1}>อนุมัติ</option>
                          <option value={2} selected={selectedStudentAffairsTeacher === 2}>ไม่อนุมัติ</option>
                      </select>
                      <FormHelperText>สถานะตอนนี้ : {detail.student_affairs_teacher}</FormHelperText>
                  </FormControl>
              )}

              {user.acc_class_room === detail.acc_class_room && (
                  <FormControl sx={{ m: 1 }} fullWidth>
                      <label id="class-instructor-label">สถานะครูประจำชั้น</label>
                      <select
                      labelId="class-instructor-label"
                      value={selectedClassInstructor}
                      onChange={(e) => setSelectedClassInstructor(e.target.value)}
                      className="block w-full p-3 mt-2 border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                      >
                          <option value={1} selected={detail.class_instructor === 1 || detail.class_instructor === "อนุมัติ" }>อนุมัติ</option>
                          <option value={2} selected={detail.class_instructor === 2 || detail.class_instructor === "ไม่อนุมัติ" }>ไม่อนุมัติ</option>
                      </select>
                      <FormHelperText>สถานะตอนนี้ : {detail.class_instructor}</FormHelperText>
                  </FormControl>
              )}
              </Grid>
            </Grid>
            <Button
              onClick={handleSaveChanges}
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#f25cc0",
                "&:hover": {
                  backgroundColor: "#ef6694",
                },
              }}
            >
              บันทึกการเปลี่ยนแปลง
            </Button>
          </Box>
        </Box>
      </Container>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" gutterBottom>
            MTwit Service's Beta
          </Typography>
          <Typography variant="h6" gutterBottom>
            กำลังดึงข้อมูล....
          </Typography>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default HistoryOutDetailTeacher;
