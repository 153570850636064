import React, { useState, useEffect } from "react";
import { Container, Avatar } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import AppBarTeh from "../../components/AppBarTeh";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Backdrop, CircularProgress, CssBaseline } from "@mui/material";

const StOut = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [out, setOut] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [user, setUser] = useState({});

  const fetchData = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/teh/history_out_st`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOut(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert(
        "คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อควาามปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/teh/user`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 200) {
          setUser(data.user);
        } else {
          localStorage.removeItem('token');
          navigate('/app/teacher/teh-login');
        } 
      })
      .catch(error => {
        localStorage.removeItem('token');
          navigate('/app/teacher/teh-login');
          alert('คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง');
      });
  }, []);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const columns = [
    {
      field: "id",
      headerName: "ชื่อ - สกุล",
      width: 200,
      valueGetter: (params) => {
        const { acc_firstname, acc_surname } = params.row.user;
        return `${acc_firstname} ${acc_surname}`;
      },
    },
    {
      field: "reason",
      headerName: "เหตุผล",
      width: 500,
    },
    {
      field: "time_out",
      headerName: "เวลาไป",
      width: 100,
    },
    {
      field: "time_in",
      headerName: "เวลากลับ",
      width: 100,
    },
    {
      field: "date_create",
      headerName: "วันเวลาที่สร้าง",
      width: 200,
    },
    {
      field: "all_status_thai",
      headerName: "สถานะ",
      width: 150,
    },
    {
      field: "actions",
      headerName: "จัดการ",
      width: 100,
      renderCell: (params) => (
        <Button onClick={() => handleViewClick(params.row.id)} variant="text">
          ดูรายละเอียด
        </Button>
      ),
    },
  ];

  const [open, setOpen] = React.useState(false);

  const handleViewClick = (id) => {
    setOpen(true);
    setTimeout(() => {
      navigate(`/app/teacher/detail/${id}`);
    }, 2000);
  };

  const getClassRoomInfo = (classRoomString) => {
    if (classRoomString) {
      const classRoom = classRoomString.split('/');
      const classNumber = classRoom[0];
      const roomNumber = classRoom[1];
      return { classNumber, roomNumber };
    } else {
      return { classNumber: '', roomNumber: '' };
    }
  };

  const { classNumber, roomNumber } = getClassRoomInfo(user.acc_class_room);


  return (
    <>
      <AppBarTeh />
      <div className="min-h-screen flex justify-center items-center p-8 text-gray-900">
    <div style={{ height: 400, width: '100%' }}>
    <div className="i-container mr-4 ">
            <div style={{ minWidth: "120px" }}>
              <img
                src={
                  user.acc_line_pictureUrl ||
                  "/img/MTwit.jpg"
                }
                alt="Logo_Game"
                className="p-1 rounded-full ring-2 ring-gray-300 "
                width={96}
                height={96}
              />
            </div>
            <div
              className="i-container"
              style={{
                justifyContent: "flex-start",
                minWidth: "200px",
              }}
            >
              <div style={{ width: "100%" }}>
              <h1 className="text-xl font-bold">ประวัติออกนอกโรงเรียน </h1>
              <h1 className="text-sm text-gray-500">มีแค่ออกนอกโรงเรียน ไม่มีวันออกไปจูงมือเขาหรอก</h1>
                <h1 className="text-2xl font-bold">
                  ของ {user.acc_firstname} {user.acc_surname}
                </h1>
                <h6 className="mb-0">รหัสประจำตัว : {user.acc_username}</h6>
                {user.acc_class_room && (
                  <>
                    <h6 className="mb-0">นักเรียนมัธยมศึกษาปีที่: {classNumber} ห้อง {roomNumber}</h6>
                  </>
                )}

              </div>
            </div>
          </div>
        <DataGrid
        className="mt-2"
        columns={columns}
        rows={out}
        pagination
        onPageChange={(params) => handleChangePage(params.page)}
        onPageSizeChange={(params) => {
          setPage(0);
        }}
        pageSize={rowsPerPage}
        rowCount={out.length}
        />
      </div>
    </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default StOut;
