import React, { useState, useEffect } from "react";
import { Container, Avatar } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import AppBarAdmin from "../../components/AppBarAdmin";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  CssBaseline,
  Button,
  Backdrop,
  CircularProgress,
  TextField,
  Grid,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as XLSX from "xlsx";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const AllStudents = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [out, setOut] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [user, setUser] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openadd, setOpenAdd] = React.useState(false);
  const [excelData, setExcelData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [acc_username, setAcc_username] = useState("");
  const [acc_firstname, setAcc_firstname] = useState("");
  const [acc_surname, setAcc_surname] = useState("");
  const [acc_class_room, setAcc_class_room] = useState("");

  const fetchData = async () => {

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/backend/studentAll`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const formattedData = response.data.map((student, index) => ({
        ...student,
        id: student.acc_username,
      }));
      setLoading(false);
      setOut(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    
    fetchData();
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
        } else {
          localStorage.removeItem("token");
          navigate("/app/backend/login");
        }
      })
      .catch((error) => {
        localStorage.removeItem("token");
        navigate("/app/backend/login");
        alert(
          "คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        );
      });
  }, []);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatDateTime = (dateTime) => {
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric',
      timeZone: 'Asia/Bangkok'  // เปลี่ยน timeZone เป็น Asia/Bangkok
    };
    return dateTime.toLocaleString('th-TH', options);
  };


  const handleExportXLSX = async (event) => {
    event.preventDefault(); 
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/backend/studentAll`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // แปลงข้อมูลให้เหลือเฉพาะที่ต้องการ
      const filteredData = response.data.map(item => ({
        "รหัสประจำตัว": item.acc_username,
        "ชื่อจริง": item.acc_firstname,
        "นามสกุล": item.acc_surname,
        "ห้อง": item.acc_class_room,
        "วันที่ส่งออกข้อมูล" : formatDateTime(currentDateTime),
      }));
  
      // สร้าง Workbook
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(filteredData);
  
      // เพิ่ม Worksheet ลงใน Workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      // สร้างไฟล์ Excel
      XLSX.writeFile(wb, `ข้อมลูนักเรียน-${formatDateTime(currentDateTime)}.xlsx`);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const columns = [
    {
      field: "acc_username",
      headerName: "รหัสประจำตัว",
      width: 200,
    },
    {
      field: "id",
      headerName: "ชื่อ - สกุล",
      width: 200,
      valueGetter: (params) => {
        const { acc_firstname, acc_surname } = params.row;
        return `${acc_firstname} ${acc_surname}`;
      },
    },
    {
      field: "acc_class_room",
      headerName: "ชั้นเรียน",
      width: 100,
    },
    {
      field: "acc_line_displayName",
      headerName: "ชื่อที่เชื่อมต่อใน Line",
      width: 200,
    },
    {
      field: "acc_line_user_id",
      headerName: "Line ID",
      width: 100,
    },
    {
      field: "actions",
      headerName: "จัดการ",
      width: 150,
      renderCell: (params) => (
        <>
            <Button
              variant="contained"
              color="info"
              startIcon={<InfoOutlinedIcon />}
              onClick={() => handleViewClick(params.row.acc_username)}
            >
              ดูรายละเอียด
            </Button>
      </>
      ),
    },
    {
      field: "del",
      headerName: "ลบ",
      width: 100,
      renderCell: (params) => (
        <>
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={() => handlDellick(params.row.acc_username)}
            >
              ลบ
            </Button>
      </>
      ),
    },
  ];

  const handleViewClick = (acc_username) => {
    setLoading(true);
    setTimeout(() => {
      navigate(`/app/backend/student/${acc_username}`);
      setLoading(false);
    }, 2000);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file && file.name.endsWith(".xlsx")) {
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        console.log(jsonData.length);

        setExcelData(jsonData);
        setOpen(true);
      };
    } else {
      alert("โปรดเลือกไฟล์ Excel เท่านั้น");
    }

    reader.readAsArrayBuffer(file);
  };

  const handlDellick = async (acc_username) => {
    try {
      setLoading(true);
      const requestData = {
        acc_username,
      };
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/delStudent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      if (data.status === 200) {
        setLoading(false);
        toast.success(data.message);
        fetchData();
      } else {
        setLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      setLoading(false);
      alert("เกิดข้อผิดพลาดระหว่างการลบ:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAdd(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      setOpen(false);
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/upXLSX`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        body: JSON.stringify(excelData),
      });

      const data = await response.json();
      if (response.status === 201) {
        setLoading(false);
        toast.success(data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        fetchData();
        // navigate("/app/backend/allStudent");
      } else {
        setLoading(false);
        toast.error(data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      setLoading(false);
      alert("เกิดข้อผิดพลาดระหว่างการอัพโหลด:", error);
    }
  };

  const handleAddUser = async (event) => {
    event.preventDefault(); 

    if (!acc_username || !acc_firstname || !acc_surname || !acc_class_room) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    try {
      setLoading(true);
      const requestData = {
        acc_username,
        acc_firstname,
        acc_surname,
        acc_class_room,
      };
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/add_user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      if (response.status === 201) {
        setLoading(false);
        toast.success(data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOpenAdd(false);
        setAcc_username('');
        setAcc_firstname('');
        setAcc_surname('');
        setAcc_class_room('');
        fetchData();
      } else {
        setLoading(false);
        toast.error(data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      setLoading(false);
      alert("เกิดข้อผิดพลาดระหว่างการอัพโหลด:", error);
    }
  }

  return (
    <>
      <ToastContainer />
      <AppBarAdmin />
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <CssBaseline />
        <h1>นักเรียนทั้งหมดของโรงเรียนแม่ตื่นวิทยาคม</h1>
        <div style={{ height: 750, width: "100%" }}>
          <TextField
            type="file"
            margin="normal"
            required
            fullWidth
            label="ข้อมลูนักเรียน"
            autoFocus
            onChange={handleFileUpload}
            InputLabelProps={{
              style: { color: "#80B7A2" },
            }}
          />
          <Button
            fullWidth
            onClick={() => setOpenAdd(true)}
            variant="contained"
            disableElevation
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: "#80B7A2",
              "&:hover": {
                backgroundColor: "#637870",
              },
            }}
          >
            เพิ่มนักเรียนรายคน
          </Button>
          <Button
            fullWidth
            onClick={handleExportXLSX}
            variant="contained"
            disableElevation
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: "#80B7A2",
              "&:hover": {
                backgroundColor: "#637870",
              },
            }}
          >
            Export XLSX
          </Button>
          <DataGrid
            columns={columns}
            rows={out}
            pagination
            onPageChange={(params) => handleChangePage(params.page)}
            onPageSizeChange={(params) => {
              setPage(0);
            }}
            pageSize={rowsPerPage}
            rowCount={out.length}
          />
        </div>
      </Container>
      {excelData && (
        <Dialog
          open={open}
          scroll={"paper"}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ข้อมลูนักเรียน ใน ExcelData ทั้งหมด {excelData.length} คน
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {excelData.map((excelData) => (
                <pre>
                  รหัสประจำตัว: {excelData[0]} ชื่อ - สกุล: {excelData[1]}{" "}
                  {excelData[2]} ชั้น: {excelData[3]}{" "}
                </pre>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit}>อัพโหลด</Button>
            <Button onClick={handleClose}>ปิด</Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={openadd}>
        <DialogTitle>เพิ่มนักเรียนรายคน</DialogTitle>
        <DialogContent>
          {/* <DialogContentText></DialogContentText> */}
          <Grid container spacing={2} component="form" onSubmit={handleAddUser}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                fullWidth
                label="ชื่อ"
                value={acc_firstname}
                onChange={(e) => setAcc_firstname(e.target.value)}
                InputLabelProps={{
                  style: { color: "#80B7A2" },
                }}
                sx={{
                  "& input": {
                    color: "#80B7A2",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                fullWidth
                label="สกุล"
                value={acc_surname}
                onChange={(e) => setAcc_surname(e.target.value)}
                InputLabelProps={{
                  style: { color: "#80B7A2" },
                }}
                sx={{
                  "& input": {
                    color: "#80B7A2",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label="รหัสประจำตัว"
                autoComplete="family-name"
                value={acc_username}
                onChange={(e) => setAcc_username(e.target.value)}
                InputLabelProps={{
                  style: { color: "#80B7A2" },
                }}
                sx={{
                  "& input": {
                    color: "#80B7A2",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="มัธยมศึกษาปีที่"
                autoComplete="family-name"
                value={acc_class_room}
                onChange={(e) => setAcc_class_room(e.target.value)}
                InputLabelProps={{
                  style: { color: "#80B7A2" },
                }}
                sx={{
                  "& input": {
                    color: "#80B7A2",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
              fullWidth
              type="submit"
              variant="contained"
              disableElevation
              sx={{
                mt: 1,
                mb: 2,
                backgroundColor: "#80B7A2",
                "&:hover": {
                  backgroundColor: "#637870",
                },
              }}
            >
              เพิ่มนักเรียน
            </Button>
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AllStudents;
