import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';

import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {useState, useEffect} from 'react'

import AccountCircle from '@mui/icons-material/AccountCircle';


export default function MenuAppBarTeh() {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = useState([]);
  const open = Boolean(anchorEl);
  const token = localStorage.getItem('token');
  const [backdrop, setBackdrop] = React.useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const isMenuOpen = Boolean(menuAnchor);

  useEffect(() => {
    const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/user`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            const data = await response.json();
            if (data.status === 200) {
              setUser(data.user)
              return;
            } else if (data.status === 403 || data.status === 401) {
              localStorage.removeItem('token')
              navigate('/app/backend/login');
              return;
            } 
    };
    fetchData(); // เรียกใช้ฟังก์ชัน fetchData
}, []);



const logout = () => {
  setBackdrop(true)
  setTimeout(() => {
    setBackdrop(false)
    localStorage.removeItem('token')
    localStorage.removeItem('isFirstLoad');
    toast.success('ออกจากระบบสำเร็จ');
    navigate('/app/backend/login')
  }, 2000); 
  
}

  return (
    <>
      <ToastContainer />
      <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: '#80B7A2' }}>
        <Toolbar>
        <Typography variant="subtitle1" onClick={() => navigate('/app/backend/')}  sx={{ flexGrow: 1 }}>
           <h4>ระบบจัดการออกนอกโรงเรียนแม่ตื่นวิทยาคม</h4>
        </Typography>
              <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
               {token == null ? (
                  <AccountCircle />
                 ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        alt={user.acc_username}
                        title={`${user.acc_username}`}
                        src="https://obs.line-scdn.net/0hMBnWk0qZEm1TLgCwSMFtOgFzGQ9gTAxmcRoGCjNSTjp9TSNyJho7D3ZRTwB9YQ1QJCk7SjBRPjZ_fzNbMx04Cj9GKjk4TSxQMCA7Aj1-Og84ZQlhZzQt/f256x256"
                        sx={{ width: 40, height: 40, margin: '0 auto' }}
                      />
                  </div>
                 )}
              </IconButton>
          <Menu
              id="menu-user"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuList dense sx={{ width: 300 }}>
                <MenuItem>
                <ListItemText>{process.env.REACT_APP_V}</ListItemText>
                </MenuItem>
                {token == null ? (
                  [
                    <Divider key="divider1" />,
                    <MenuItem key="student-login" onClick={() => navigate('/app/login')}>
                      <ListItemText>เข้าสู่ระบบนักเรียน</ListItemText>
                    </MenuItem>,
                    <MenuItem key="teacher-login" onClick={() => navigate('/app/teacher/teh-login')}>
                      <ListItemText>เข้าสู่ระบบครู</ListItemText>
                    </MenuItem>,
                    <MenuItem key="backend-admin" onClick={() => navigate('/app/backend/login')}>
                      <ListItemText>เข้าสู่ระบบแอดมิน</ListItemText>
                    </MenuItem>,
                    <Divider key="divider2" />,
                    <MenuItem key="support" as="a" href='https://liff.line.me/1645278921-kWRPP32q/?accountId=010nlxid'>
                      <ListItemText>ติดต่อผู้ดูแลระบบ</ListItemText>
                    </MenuItem>
                  ]
                ) : (
                  [
                    <Divider key="divider1" />,
                    <MenuItem onClick={() => navigate('/app/backend/profile')}>
                      <ListItemText>เปลี่ยนรหัส</ListItemText>
                    </MenuItem>,
                    <MenuItem onClick={() => navigate('/app/backend/allStudent')}>
                      <ListItemText>จัดการข้อมูลนักเรียน</ListItemText>
                    </MenuItem>,
                    <MenuItem onClick={() => navigate('/app/backend/allTeacher')}>
                        <ListItemText>จัดการข้อมูลบุคลากร</ListItemText>
                    </MenuItem>,
                    <MenuItem onClick={() => navigate('/app/backend/statistics')}>
                        <ListItemText>สถิตการออกเยอะสุด</ListItemText>
                    </MenuItem>,
                    <MenuItem onClick={() => navigate('/app/backend/setting')}>
                      <ListItemText>ตั้งค่า</ListItemText>
                    </MenuItem>,
                    <Divider key="divider2" />,
                    <MenuItem onClick={logout}>
                    <ListItemText>ออกจากระบบ</ListItemText>
                    </MenuItem>
                  ]
                )}
              </MenuList>
            </Menu>
        </Toolbar>
      </AppBar>
    </Box>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
