import React, { useState, useEffect } from "react";
import { useNavigate,useLocation  } from "react-router-dom";
import { toast } from "react-toastify";
import "./PayFood.css";
import ReCAPTCHA from 'react-google-recaptcha';


export const PayFood = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ref = searchParams.get('ref');
  const transfer = searchParams.get('transfer');
  const token = localStorage.getItem("token");

  const [d, setD] = useState({});
  const [s, setS] = useState({});
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    if (value) {
      setIsRecaptchaVerified(true);
      console.log(value);
    }
  };

  if (!isRecaptchaVerified) {
    console.log("โปรดยืนยัน reCAPTCHA ก่อน");
   
  }

  useEffect(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/PayFood`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ transfer,ref }),
      });
  
      const data = await response.json();
  
      if (data.status === 200) {
        setData(data);
        setS(data.account);
        setD(data.data);
        setIsLoading(false);
      } else {
        // navigate("/QrCheckPay");
      }
  
    } catch (error) {
      setIsLoading(false);
      toast.error('Error sending data to the API', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, []);
  return (
    <div className="pay-food">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="rectangle" />
          <div className="view">
            <div className="text-wrapper">ถึง</div>
          </div>
          <div className="div">
            <div className="text-wrapper-2">จาก</div>
          </div>
          <div className="div-2">
            <img className="image" alt="Image" src="/img/bibimbap.png" />
            <div className="text-wrapper-3">{d.name}</div>
            <div className="text-wrapper-4">รหัสร้านค้า {d.transfer}</div>
          </div>
          <div className="div-3">
            <img className="cat" alt="Cat" src="/img/v2_125.png" />
            <div className="text-wrapper-5">{s.acc_firstname} {s.acc_surname}</div>
            <div className="text-wrapper-6">{s.acc_username} ยอดเงินคงเหลือ {s.mt_money} บาท {s.mt_point} Point </div>
          </div>
          <div className="view-2">
            <div className="text-wrapper-7">เช็คความถูกต้อง</div>
            <p className="p">วันที่ : {data.date}</p>
            <div className="text-wrapper-8">{ref}</div>
          </div>
          <div className="view-3">
            <div className="overlap-group">
              <div className="rectangle-2" />
              <div className="text-wrapper-9">ชำระเงิน</div>
            </div>
            <ReCAPTCHA mt={""}
                sitekey={'6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}
                onChange={handleRecaptchaChange}
              />
          </div>
          <div className="element">จำนวนเงิน&nbsp;&nbsp;20 บาท</div>
        </div>
      </div>
    </div>
  );
};

export default PayFood;