import React, { useState, useEffect } from "react";
import { Container, Avatar } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import AppBarAdmin from "../../components/AppBarAdmin";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  CssBaseline,
  Button,
  Backdrop,
  CircularProgress,
  TextField,
  Grid,
  InputLabel,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as XLSX from "xlsx";


import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';



import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminallTeacher = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [out, setOut] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [user, setUser] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openadd, setOpenAdd] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [acc_username, setAcc_username] = useState("");
  const [acc_firstname, setAcc_firstname] = useState("");
  const [acc_surname, setAcc_surname] = useState("");
  const [acc_class_room, setAcc_class_room] = useState("");

  const randomNum = async () => {
    const randomNum = Math.floor(100000 + Math.random() * 900000); // สุ่มตัวเลข 6 หลัก
    setAcc_username(`TEH-${randomNum.toString()}`); // แปลงเป็น string และเซ็ตใน state
  };



  const fetchData = async () => {
    const token = localStorage.getItem("token");

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/backend/TeacherAll`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const formattedData = response.data.map((student, index) => ({
        ...student,
        id: student.acc_username,
      }));
      setLoading(false);
      setOut(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    randomNum();
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
        } else {
          localStorage.removeItem("token");
          navigate("/app/backend/login");
        }
      })
      .catch((error) => {
        localStorage.removeItem("token");
        navigate("/app/backend/login");
        alert(
          "คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        );
      });
  }, []);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatDateTime = (dateTime) => {
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric',
      timeZone: 'Asia/Bangkok'  // เปลี่ยน timeZone เป็น Asia/Bangkok
    };
    return dateTime.toLocaleString('th-TH', options);
  };


  const handleExportXLSX = async (event) => {
    event.preventDefault(); 
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/backend/TeacherAll`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // แปลงข้อมูลให้เหลือเฉพาะที่ต้องการ
      const filteredData = response.data.map(item => ({
        "ชื่อผู้ใช้": item.acc_username,
        "ชื่อจริง": item.acc_firstname,
        "นามสกุล": item.acc_surname,
        "ห้อง": item.acc_class_room,
        "วันที่ส่งออกข้อมูล" : formatDateTime(currentDateTime),
      }));
  
      // สร้าง Workbook
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(filteredData);
  
      // เพิ่ม Worksheet ลงใน Workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      // สร้างไฟล์ Excel
      XLSX.writeFile(wb, `ข้อมลูครู-${formatDateTime(currentDateTime)}.xlsx`);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = [
    {
      field: "acc_username",
      headerName: "รหัสประจำตัว",
      width: 200,
    },
    {
      field: "id",
      headerName: "ชื่อ - สกุล",
      width: 200,
      valueGetter: (params) => {
        const { acc_firstname, acc_surname } = params.row;
        return `${acc_firstname} ${acc_surname}`;
      },
    },
    {
      field: "acc_class_room",
      headerName: "ครูประจำชั้นเรียน",
      width: 200,
    },
    {
      field: "acc_role",
      headerName: "สถานะ",
      width: 200,
    },
    {
      field: "actions",
      headerName: "จัดการ",
      width: 150,
      renderCell: (params) => (
        <>
            <Button
              variant="contained"
              color="info"
              startIcon={<InfoOutlinedIcon />}
              onClick={() => handleViewClick(params.row.acc_username)}
            >
              ดูรายละเอียด
            </Button>
      </>
      ),
    },
    {
      field: "del",
      headerName: "ลบ",
      width: 100,
      renderCell: (params) => (
        <>
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={() => handlDellick(params.row.acc_username)}
            >
              ลบ
            </Button>
      </>
      ),
    },
  ];

  const handleViewClick = (acc_username) => {
    setLoading(true);
    setTimeout(() => {
      navigate(`/app/backend/Teacher/?acc_username=${acc_username}`);
      setLoading(false);
    }, 2000);
  };

  const handlDellick = async (acc_username) => {
    try {
      setLoading(true);
      const requestData = {
        acc_username,
      };
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/delTeacher`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      if (data.status === 200) {
        setLoading(false);
        toast.success(data.message);
        fetchData();
      } else {
        setLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      setLoading(false);
      alert("เกิดข้อผิดพลาดระหว่างการลบ:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAdd(false);
  };

  const handleAddUser = async (event) => {
    event.preventDefault(); 

    if (!acc_username || !acc_firstname || !acc_surname || !acc_class_room) {
      toast.warn("กรุณากรอกข้อมูลให้ครบถ้วน", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    try {
      setLoading(true);
      const requestData = {
        acc_username,
        acc_firstname,
        acc_surname,
        acc_class_room,
      };
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/add_teh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      if (response.status === 201) {
        setLoading(false);
        toast.success(data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        fetchData();
        setOpenAdd(false);
        setAcc_username('');
        setAcc_firstname('');
        setAcc_surname('');
        setAcc_class_room('');
        randomNum();
      } else {
        setLoading(false);
        toast.error(data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      setLoading(false);
      alert("เกิดข้อผิดพลาดระหว่างการอัพโหลด:", error);
    }
  }

  return (
    <>
      <ToastContainer />
      <AppBarAdmin />
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <CssBaseline />
        <h1>บุคลากรทั้งหมดของโรงเรียนแม่ตื่นวิทยาคม</h1>
        <div style={{ height: 750, width: "100%" }}>
          <Button
            fullWidth
            onClick={() => setOpenAdd(true)}
            variant="contained"
            disableElevation
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: "#80B7A2",
              "&:hover": {
                backgroundColor: "#637870",
              },
            }}
          >
            เพิ่มบุคลากร
          </Button>
          <Button
            fullWidth
            onClick={handleExportXLSX}
            variant="contained"
            disableElevation
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: "#80B7A2",
              "&:hover": {
                backgroundColor: "#637870",
              },
            }}
          >
            Export XLSX
          </Button>
          <DataGrid
            columns={columns}
            rows={out}
            pagination
            onPageChange={(params) => handleChangePage(params.page)}
            onPageSizeChange={(params) => {
              setPage(0);
            }}
            pageSize={rowsPerPage}
            rowCount={out.length}
          />
        </div>
      </Container>
      <Dialog open={openadd}>
        <DialogTitle>เพิ่มบุคลากร</DialogTitle>
        <DialogContent>
          {/* <DialogContentText></DialogContentText> */}
          <Grid container spacing={2} component="form" onSubmit={handleAddUser}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                fullWidth
                label="ชื่อ"
                value={acc_firstname}
                onChange={(e) => setAcc_firstname(e.target.value)}
                InputLabelProps={{
                  style: { color: "#80B7A2" },
                }}
                sx={{
                  "& input": {
                    color: "#80B7A2",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                fullWidth
                label="สกุล"
                value={acc_surname}
                onChange={(e) => setAcc_surname(e.target.value)}
                InputLabelProps={{
                  style: { color: "#80B7A2" },
                }}
                sx={{
                  "& input": {
                    color: "#80B7A2",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="text"
                label="ชื่อผู้ใช้"
                autoComplete="family-name"
                value={acc_username}
                onChange={(e) => setAcc_username(e.target.value)}
                InputLabelProps={{
                  style: { color: "#80B7A2" },
                }}
                sx={{
                  "& input": {
                    color: "#80B7A2",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel id="demo-simple-select-label">มัธยมศึกษาปีที่</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={acc_class_room}
                onChange={(e) => setAcc_class_room(e.target.value)}
              >
                {/* สร้าง MenuItem สำหรับแต่ละตัวเลือก */}
                <MenuItem value="1/1">1/1</MenuItem>
                <MenuItem value="1/2">1/2</MenuItem>
                <MenuItem value="1/3">1/3</MenuItem>
                
                <MenuItem value="2/1">2/1</MenuItem>
                <MenuItem value="2/2">2/2</MenuItem>
                <MenuItem value="2/3">2/3</MenuItem>

                <MenuItem value="3/1">3/1</MenuItem>
                <MenuItem value="3/2">3/2</MenuItem>
                <MenuItem value="3/3">3/3</MenuItem>

                <MenuItem value="4/1">4/1</MenuItem>
                <MenuItem value="4/2">4/2</MenuItem>
                <MenuItem value="4/3">4/3</MenuItem>
                <MenuItem value="4/4">4/4</MenuItem>

                <MenuItem value="5/1">5/1</MenuItem>
                <MenuItem value="5/2">5/2</MenuItem>
                <MenuItem value="5/3">5/3</MenuItem>
                <MenuItem value="5/4">5/4</MenuItem>

                <MenuItem value="6/1">6/1</MenuItem>
                <MenuItem value="6/2">6/2</MenuItem>
                <MenuItem value="6/3">6/3</MenuItem>
                <MenuItem value="6/4">6/4</MenuItem>

                

              </Select>
            </Grid>

            <Grid item xs={12}>
              <Button
              fullWidth
              type="submit"
              variant="contained"
              disableElevation
              sx={{
                mt: 1,
                mb: 2,
                backgroundColor: "#80B7A2",
                "&:hover": {
                  backgroundColor: "#637870",
                },
              }}
            >
              เพิ่มบุคลากร
            </Button>
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AdminallTeacher;
