import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  CssBaseline,
  Stack,
  Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppBarTeh from "../../components/AppBarTeh";

import TransferWithinAStationTwoToneIcon from '@mui/icons-material/TransferWithinAStationTwoTone';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';


const HomeTeacher = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/teh/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
        } else {
          localStorage.removeItem("token");
          navigate("/app/teacher/teh-login");
        }
      })
      .catch((error) => {
        localStorage.removeItem("token");
        navigate("/app/teacher/teh-login");
        alert(
          "คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        );
      });
  }, []);

  const currentTime = new Date();
  const hours = currentTime.getHours();
  let greeting = "";

  if (hours >= 5 && hours < 12) {
    greeting = "สวัสดี ตอนเช้า";
  } else if (hours >= 12 && hours < 17) {
      greeting = "สวัสดี ตอนบ่าย";
  } else if (hours >= 17 && hours < 18) {
      greeting = "สวัสดี ตอนเย็น";
  } else if (hours >= 19 && hours < 20) {
      greeting = "สวัสดี ตอนหัวค่ำ";
  } else {
      greeting = "สวัสดี ตอนดึก";
  }

  function getClassRoomInfo(classRoomString) {
    if (classRoomString) {
      const classRoom = classRoomString.split('/');
      const classNumber = classRoom[0];
      const roomNumber = classRoom[1];
      return { classNumber, roomNumber };
    } else {
      return { classNumber: '', roomNumber: '' };
    }
  }
  
  const { classNumber, roomNumber } = getClassRoomInfo(user.acc_class_room);
  return (
    <>
      <AppBarTeh />
      <div className="min-h-screen flex justify-center items-center mx-auto p-3">
        <div className="grid grid-cols-1 md:grid-cols-2 mt-24 md:mt-7">
          <div className="i-container mr-4 ">
            <div style={{ minWidth: "120px" }}>
              <img
                src={
                  user.acc_line_pictureUrl ||
                  "/img/MTwit.jpg"
                }
                alt="Logo_Game"
                className="p-1 rounded-full ring-2 ring-gray-300 "
                width={96}
                height={96}
              />
            </div>
            <div
              className="i-container"
              style={{
                justifyContent: "flex-start",
                minWidth: "200px",
              }}
            >
              <div style={{ width: "100%" }}>
                <h1 className="text-xl font-bold"> {greeting} </h1>
                <h1 className="text-1xl font-bold">
                  ยินดีตอนรับ คุณครู {user.acc_firstname} {user.acc_surname}
                </h1>
                <h6 className="mb-0">รหัสประจำตัว : {user.acc_username}</h6>
                {user.acc_class_room && (
                  <>
                    <h6 className="mb-0">คุณครูประจำชั้นมัธยมศึกษาปีที่: {classNumber} ห้อง {roomNumber}</h6>
                  </>
                )}

              </div>
            </div>
          </div>

          <div className="i-container mr-4 mt-3">
            <div
              class="flex p-4 mb-4 text-sm  rounded-lg bg-gray-800 border border-blue-300 text-blue-300"
              role="alert"
            >
              <svg
                class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span class="sr-only">Info</span>
              <div>
                <span class="font-medium">ตรงนี้มีประกาศนะ :</span>
                <ul class="mt-1.5 list-disc list-inside">
                  <li>ปิดเทอมมมมมมมมมมมมมมมมมมมมมมมมมมมมมมมม</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="block w-full max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 mt-2">
            <i class="bi bi-escape text-5xl text-pink-600"></i>
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
              {user.student_out_week} ครั้ง
            </h5>
            <p class="font-normal text-gray-700"> นักเรียน ห้อง {user.acc_class_room} ออกนอกโรงเรียนสัปดาห์นี้</p>
          </div>

          <div class="block w-full max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 mt-2">
            <i class="bi bi-escape text-5xl text-pink-600"></i>
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
              {user.student_out_month} ครั้ง
            </h5>
            <p class="font-normal text-gray-700"> นักเรียน ห้อง {user.acc_class_room} ออกนอกโรงเรียนเดือนนี้</p>
          </div>

          <div class="block w-full max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 mt-2">
            <i class="bi bi-exclamation-triangle text-5xl text-pink-600"></i>
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
              {user.total_count_not_approved} รายการ
            </h5>
            <p class="font-normal text-gray-700">นักเรียน ห้อง {user.acc_class_room} ที่ไม่ได้อนุมัติในสัปดาห์นี้</p>
          </div>

          <div class="block w-full max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 mt-2">
            <i class="bi bi-hourglass-split text-5xl text-pink-600"></i>
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
              {user.instructor_count_wait} รายการ
            </h5>
            <p class="font-normal text-gray-700"> นักเรียน ห้อง {user.acc_class_room} ที่รออนุมัติ</p>
          </div>

        </div>
      </div>
    </>
  );
};

export default HomeTeacher;
