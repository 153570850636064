import React, { useState, useRef } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsQR from "jsqr";
import { useNavigate } from "react-router-dom";
import "./qr-check-pay.css";


import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const QrCheckPay = () => {
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  

  const handleScan = async (code) => {
    if (code) {
      setIsLoading(true);
      try {

        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/QrCheckPay`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ code : code }),
        });

        const data = await response.json();
        
        if(data.status === 200){
          
          navigate(data.callbackUrl);
        } else {
          setIsLoading(false);
          toast.error(data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }

      } catch (error) {
        setIsLoading(false);
        toast.error('Error sending data to the API', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      }
      // toast.success(data);
    } else {
      setIsLoading(false);
      toast.error('ไม่พบรหัส QR', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  };

  const handleError = (error) => {
    console.log(error?.message);
  };

  const scanCode = (file) => {
    try {
      const imageUrl = URL.createObjectURL(file);
      const image = new Image();
      image.src = imageUrl;

      image.onload = async () => {
        const canvas = document.createElement("canvas");
        const canvasContext = canvas.getContext("2d");

        canvas.width = image.width;
        canvas.height = image.height;
        canvasContext.drawImage(image, 0, 0, canvas.width, canvas.height);

        const imageData = canvasContext.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
          try {

            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/QrCheckPay`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ code : code.data }),
            });

            const data = await response.json();
            
            if(data.status === 200){
              navigate(data.callbackUrl);
            } else {
              setIsLoading(false);
              toast.error(data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            }

          } catch (error) {
            setIsLoading(false);
            toast.error("Error sending data to the API", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
          }
        } else {
          setIsLoading(false);
            toast.error("ไม่พบรหัส QR", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
        }
      };
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      alert("เกิดข้อผิดพลาดในการอ่านคิวอาร์โค้ด");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      scanCode(file);
      setIsLoading(true);
    }
  };
  
  

  return (
    <div className="qr-check-pay">
      <div className="overlap-group-wrapper">
        <div className="overlap-group">
          <div className="rectangle" />
          <div className="div" />
          <div className="text-wrapper">Qr Check &amp; Pay</div>
          <div className="text-wrapper-2">MTWIT Service</div>
          <div className="image"> <QrScanner  onDecode={handleScan} onError={handleError} /></div>
          <img className="img" alt="Image" onClick={() => fileInputRef.current.click()} src="./img/v1_13.png" />
          <input type="file" className="img" accept="image/*" ref={fileInputRef}  onChange={handleFileChange}  style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer",
          }} />
          <div className="view">
            <div className="text-wrapper-3">อัพโหลด บัตรนักเรียนหรือบัตรอาหาร</div>
          </div>
          <div className="view-2">
            <div className="text-wrapper-4">สแกน บัตรนักเรียนหรือบัตรอาหาร</div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default QrCheckPay;