import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuAppBar from "../../components/AppBar";
import { toast} from 'react-hot-toast';


const Setting = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [editedUser, setEditedUser] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });
  const [backdrop, setBackdrop] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user)
          setProfileImageUrl(data.user.acc_line_pictureUrl);
        } else if (data.status === 403 || data.status === 401) {
          localStorage.removeItem("token");
          navigate("/app/login");
        } else if (data.status === 503) {
          localStorage.removeItem("token");
          navigate("/app/login");
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, []);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedUser((prevEditedUser) => ({
      ...prevEditedUser,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = async () => {
    setBackdrop(true);
    try {
      // เรียก API เพื่อเปลี่ยนรหัสผ่าน
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/change-password`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedUser),
        }
      );

      const data = await response.json();

      if (data.status === 200) {
        // แสดง Toast แจ้งเตือนสำเร็จ
        toast.success(data.message, {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // รีเซ็ตข้อมูลที่แก้ไข
        setEditedUser({
          oldPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        });
        // ปิดโหมดแก้ไข
        setIsEditing(false);
      } else if (data.status === 401) {
        // แสดง Toast แจ้งเตือนเกิดข้อผิดพลาด
        toast.error(data.message, {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setBackdrop(false);
    }
  };


  function getClassRoomInfo(classRoomString) {
    if (classRoomString) {
      const classRoom = classRoomString.split('/');
      const classNumber = classRoom[0];
      const roomNumber = classRoom[1];
      return { classNumber, roomNumber };
    } else {
      return { classNumber: '', roomNumber: '' };
    }
  }
  
  const { classNumber, roomNumber } = getClassRoomInfo(user.acc_class_room);

  return (
    <>
      <MenuAppBar />

      <div
        className="min-h-screen flex justify-center items-center p-8 text-gray-900"
      >
        {loading ? (
             <div className="hero min-h-screen flex items-center justify-center">
             <div className="hero-content text-center">
               <div className="max-w-md">
                 <h1 className="text-3xl font-bold">กำลังเชื่อมต่อกับเซิฟเวอร์</h1>
                 <div role="status">
                   <svg
                     aria-hidden="true"
                     class="mt-4 inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                     viewBox="0 0 100 101"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <path
                       d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                       fill="currentColor"
                     />
                     <path
                       d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                       fill="currentFill"
                     />
                   </svg>
                   <span class="sr-only">Loading...</span>
                 </div>
                 <div class="mb-1 text-base font-medium dark:text-white">กรุณารอสักครู่</div>
               </div>
             </div>
           </div>
          ) : (
            <>

              <div
        className="min-h-screen flex justify-center items-center p-8 text-gray-900"
      >
    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow">
        <div class="flex justify-end px-4 pt-4">
        <span class="bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">{isEditing ? "เปลี่ยนรหัสผ่าน" : "ตั้งค่า"}</span>

        </div>
        <div class="flex flex-col items-center pb-10">
            <img class="w-24 h-24 mb-3 rounded-full shadow-lg" alt={""}
                  src={profileImageUrl || "/img/MTwit.jpg"}
                  onClick={"handleOpenImagePreview"}/>
              <input
              accept="image/*"
              style={{ display: "none" }}
              id="avatar-upload"
              type="file"
              onChange={"handleFileChange"}
            />
            <h5 class="mb-1 text-xl font-medium text-gray-900 ">{user.acc_firstname} {user.acc_surname}</h5>
            <span class="text-sm text-gray-500 ">รหัสประจำตัว </span>
            {user.acc_class_room && (
                  <>
                   <span class="text-sm text-gray-500 ">รนักเรียนมัธยมศึกษาปีที่: {classNumber} ห้อง {roomNumber}</span>
                  </>
                )}
            <div class="mt-4 w-full lg:mx-20 mx-24 p-8">
            {isEditing && (
                    <>
                      <label className="form-control flex flex-col mt-2 ">
                      <div className="label mb-2">
                        <span className="label-text-alt">
                          รหัสผ่านเดิม
                        </span>
                      </div>
                      <input
                        type="password"
                        name='oldPassword'
                        placeholder="กรุณากรอกรหัสผ่านเก่า"
                        value={editedUser.oldPassword}
                        onChange={handleInputChange}
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        required
                      />
                    </label>

                    <label className="form-control flex flex-col mt-2 ">
                      <div className="label mb-2">
                        <span className="label-text-alt">
                        รหัสผ่านใหม่
                        </span>
                      </div>
                      <input
                        type="password"
                        name='newPassword'
                        placeholder="กรุณากรอกรหัสผ่านใหม่"
                        value={editedUser.newPassword}
                        onChange={handleInputChange}
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        required
                      />
                    </label>

                    <label className="form-control flex flex-col mt-2 ">
                      <div className="label mb-2">
                        <span className="label-text-alt">
                          ยืนยันรหัสผ่านใหม่
                        </span>
                      </div>
                      <input
                        type="password"
                        name='newPasswordConfirm'
                        placeholder="กรุณายืนยันรหัสผ่านใหม่"
                        value={editedUser.newPasswordConfirm}
                        onChange={handleInputChange}
                        className="block w-full p-3  border text-sm rounded-lg w-full p-2.5 bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-500 focus:ring-pink-500 focus:border-pink-500"
                        required
                      />
                    </label>
                    </>
                  )}
              <div onClick={isEditing ? handleSave : handleEdit} class="w-full mt-3 items-center px-4  py-2 border border-gray-300 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-300"> {isEditing ? "บันทึกการเปลี่ยนแปลงรหัสผ่าน" : "เปลี่ยนรหัสผ่าน"}</div>
                    {isEditing && (
                        <div  onClick={handleCancel} class="w-full  mt-3 items-center px-4 py-2 text-sm font-medium text-center text-white bg-pink-700 rounded-lg hover:bg-pink-800 focus:ring-4 focus:outline-none focus:ring-pink-300">ยกเลิก</div>
                    )}
            
            </div>
        </div>
    </div>

      </div>
              
            </>
          )}
    </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Setting;
