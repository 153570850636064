import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import SpeedDial from "./SpeedDial";
import Announce from "./Announce"; // ปรับเปลี่ยนเส้นทางไปยังไฟล์ App Bar ของคุณ

import InactiveTime from "./InactiveTime";

import { Navbar, NavbarCollapse, NavbarToggle } from "flowbite-react";

export default function MenuAppBar() {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const token = localStorage.getItem("token");
  const [backdrop, setBackdrop] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/user`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        setUser(data.user);
        return;
      } else if (data.status === 403 || data.status === 401) {
        localStorage.removeItem("token");
        // toast.error("คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
        // navigate(`/app/login?callbackUrl=${location.pathname}`)
        //  alert('คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง');
        return;
      }
    };
    fetchData(); // เรียกใช้ฟังก์ชัน fetchData
  }, []);

  const sendMaintainRequest = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/maintain`,
        {
          method: "POST",
        }
      );
      const data = await response.json();
      if (data.status == 503) {
        navigate(`/app/Maintain`);
      }
    } catch (error) {
      // console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(sendMaintainRequest, 15000);
    return () => clearInterval(intervalId);
  }, []);

  const logout = () => {
    setBackdrop(true);
    setTimeout(() => {
      setBackdrop(false);
      localStorage.removeItem("token");
      localStorage.removeItem("acc_username");
      localStorage.removeItem("acc_password");
      localStorage.removeItem("isFirstLoad");
      toast.success("ออกจากระบบสำเร็จ");
      navigate(`/app/login?callbackUrl=${location.pathname}`);
    }, 2000); // 2000 มีนาคมหรือ 2 วินาที
  };

  return (
    <>
      {token && [<InactiveTime />]}
      <Announce />
      {token && [<SpeedDial />]}
      <Navbar fluid className="top-0 z-[999] dark:bg-white bg-white w-full">
        <div className="max-w-screen-xl flex bg-white flex-wrap items-center justify-between mx-auto p-4">
          <div
            href="#"
            onClick={() => navigate("/app/")}
            class="flex items-center space-x-3 bg-white mr-12"
          >
            <img
              src="/img/logomtwit-768x768 (3).png"
              class="h-8"
              alt="ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคม Logo"
            />
            <span class="self-center text-1xl font-semibold whitespace-nowrap">
              ระบบออกนอกโรงเรียนแม่ตื่นวิทยาคม
            </span>
          </div>

          <NavbarToggle class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" />
          <NavbarCollapse>
            {token == null ? (
              <>
                <Navbar.Link onClick={() => navigate("/app/teacher/teh-login")}>
                  <div
                    href="#"
                    class="block py-2 px-3 text-pink-50 bg-pink-700 rounded p-0"
                  >
                    เข้าสู่ระบบครู
                  </div>
                </Navbar.Link>
                <Navbar.Link onClick={() => navigate("/app/backend/login")}>
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    เข้าสู่ระบบแอดมิน
                  </div>
                </Navbar.Link>
                <Navbar.Link>
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    {process.env.REACT_APP_V}
                  </div>
                </Navbar.Link>
              </>
            ) : (
              <>
                <Navbar.Link onClick={() => navigate("/app/profile")}>
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    <i class="bi bi-person-circle"></i> โปรไฟล์
                  </div>
                </Navbar.Link>
                <Navbar.Link onClick={() => navigate("/app/setting")}>
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    <i class="bi bi-gear-wide-connected"></i> ตั้งค่า
                  </div>
                </Navbar.Link>
                <Navbar.Link onClick={() => navigate("/app/CreateNew")}>
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    <i class="bi bi-file-plus"></i> สร้างคำขอ
                  </div>
                </Navbar.Link>
                <Navbar.Link onClick={() => navigate("/app/HistoryOut")}>
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    <i class="bi bi-clock-history"></i> ประวัติออกการออก
                  </div>
                </Navbar.Link>
                <Navbar.Link onClick={() => navigate(`/app/emergency`)}>
                  <div href="#" class="block py-2 px-3 text-pink-800 rounded ">
                    <i class="bi bi-x-circle"></i> แจ้งปัญหา
                  </div>
                </Navbar.Link>
                <Navbar.Link onClick={logout}>
                  <div
                    href="#"
                    class="block py-2 px-3 text-pink-50 bg-pink-700 rounded p-0"
                  >
                    <i class="bi bi-box-arrow-left"></i> ออกจากระบบ
                  </div>
                </Navbar.Link>
              </>
            )}
          </NavbarCollapse>
        </div>
      </Navbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
