import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuAppBar from "../components/AppBar";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import axios from "axios";
import { Button, Modal } from "flowbite-react";

const Notifications = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [backdrop, setBackdrop] = React.useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const currentPath = window.location.pathname;

  useEffect(() => {
    setBackdrop(true);
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
          setBackdrop(false);
        } else {
          setBackdrop(true);
          localStorage.removeItem("token");
          navigate(`/app/login?callbackUrl=${currentPath}`);
        }
      })
      .catch((error) => {
        setBackdrop(true);
        localStorage.removeItem("token");
        navigate(`/app/login?callbackUrl=${currentPath}`);
      });
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/Get_notifications`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data && response.data.results) {
        setNotifications(response.data.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
  };

  const handleStatusChange = async (notiId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/updateNotificationReadStatus`,
        { notiId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // ปรับปรุง state หรือทำอย่างอื่นตามที่ต้องการ
        console.log("สถานะถูกเปลี่ยนแล้ว");
        fetchData();
        // ปิด dialog เมื่อสำเร็จ
        setSelectedNotification(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <MenuAppBar />
      {backdrop ? (
        <div className="hero min-h-screen flex items-center justify-center">
          <div className="hero-content text-center">
            <div className="max-w-md">
              <h1 className="text-3xl font-bold">กำลังเชื่อมต่อกับเซิฟเวอร์</h1>
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="mt-4 inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
              <div class="mb-1 text-base font-medium">กรุณารอสักครู่</div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="min-h-screen flex justify-center items-center p-8 text-gray-900">
            {" "}
            {token ? (
              <>
                <section class="bg-white dark:bg-white-900 antialiased">
                  <div class="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24">
                    <div class="max-w-3xl mx-auto text-center mt-8">
                      <h2 class="text-4xl font-extrabold leading-tight tracking-tight text-gray-900 ">
                        Notifications การแจ้งเตือนของคุณ
                      </h2>
                    </div>

                    <div class="flow-root max-w-3xl mx-auto mt-8 sm:mt-12 lg:mt-16">
                      <div class="-my-4 divide-y divide-gray-200">
                        {notifications.map((notification) => (
                          <div
                            class="flex flex-col gap-3 py-4 sm:gap-3 sm:flex-col "
                            onClick={() =>
                              handleNotificationClick(notification)
                            }
                          >
                            <p class="w-32 text-lg font-normal text-gray-500 shrink-0">
                              {notification.noti_read === "notopen" ? (
                                <ErrorOutlineRoundedIcon
                                  sx={{ color: "red" }}
                                />
                              ) : (
                                <CheckCircleOutlineRoundedIcon
                                  sx={{ color: "green" }}
                                />
                              )}
                            </p>
                            <h3 class="text-md font-semibold text-gray-900">
                              {notification.noti_heading}
                            </h3>
                            <p>{notification.noti_date}</p>
                            <div class="inline-flex text-gray-900 font-medium">
                              ดูรายละเอียด
                              <svg
                                class="w-5 h-5 ml-2 -mr-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <>
                <p>ไม่พบโทเคน</p>
              </>
            )}
          </div>
        </>
      )}

      {selectedNotification && (
        <>
          <Modal
            dismissible
            show={Boolean(selectedNotification)}
            size="2xl"
            position={"center"}
            popup
            className="z-[1000]"
            onClose={() => handleStatusChange(selectedNotification.noti_id)}
          >
            <Modal.Header>
              <h1 className="text-sm leading-relaxed text-gray-900">
                {selectedNotification.noti_heading}
              </h1>
            </Modal.Header>
            <Modal.Body className="z-[1000] dark:bg-white bg-white">
              <div className="space-y-6 text-center">
                <p className="font-semibold leading-relaxed text-gray-900">
                  {selectedNotification.noti_content}
                </p>
                <p className="font-semibold leading-relaxed text-gray-500">
                  ส่งมาเวลา {selectedNotification.noti_date}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div
                className="w-full mt-3 items-center px-4  py-2 border border-gray-300 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-300"
                onClick={() => handleStatusChange(selectedNotification.noti_id)}
              >
                ตกลง
              </div>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Notifications;
