import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  CssBaseline,
  Stack,
  Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppBarAdmin from "../../components/AppBarAdmin";
const HomeAdmin = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/backend/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUser(data.user);
        } else {
          localStorage.removeItem("token");
          navigate("/app/backend/login");
        }
      })
      .catch((error) => {
        localStorage.removeItem("token");
        navigate("/app/backend/login");
        alert(
          "คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        );
      });
  }, []);

  const currentTime = new Date();
  const hours = currentTime.getHours();
  let greeting = "";

  if (hours >= 5 && hours < 12) {
    greeting = "สวัสดีตอนเช้า";
  } else if (hours >= 12 && hours < 17) {
    greeting = "สวัสดีตอนกลางวัน";
  } else if (hours >= 17 && hours < 20) {
    greeting = "สวัสดีตอนเย็น";
  } else {
    greeting = "สวัสดีตอนดึก";
  }
  return (
    <>
      <AppBarAdmin />
      <Container component="main" maxWidth="lg" sx={{ marginTop: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>

        <CssBaseline />
        {token ? (
          <>
            <h2>
              {" "}
              {greeting} | แอดมิน
            </h2>
            <Box component="form" noValidate sx={{ mt: 3 }}>
            <Stack
              sx={{
                paddingTop: 2,
                display: "flex",
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 3,
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper
                    elevation="4"
                    sx={{
                      p: 2,
                      height: 240,
                      width: '100%', // Adjusted width to fit the container
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography component="p" variant="h4" sx={{ marginTop: 2 }}>
                      {user.all_student} คน
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                      นักเรียนทั้งหมดในโรงเรียนแม่ตื่นวิทยาคม
                    </Typography>

                    <Typography component="p" variant="h4" sx={{ marginTop: 2 }}>
                      {user.all_teh} คน
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                      บุคลากรในโรงเรียนแม่ตื่นวิทยาคม
                    </Typography>
                  </Paper>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <Paper
                    elevation="4"
                    sx={{
                      p: 2,
                      height: 240,
                      width: '100%', // Adjusted width to fit the container
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography component="p" variant="h4" sx={{ marginTop: 8 }}>
                      {user.succeed} รายการ
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                      อนุมัติไปแล้ว
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Paper
                    elevation="4"
                    sx={{
                      p: 2,
                      height: 240,
                      width: '100%', // Adjusted width to fit the container
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography component="p" variant="h4" sx={{ marginTop: 8 }}>
                      {user.wait} รายการ
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                      รออนุมัติ
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper
                    elevation="4"
                    sx={{
                      p: 2,
                      height: 240,
                      width: '100%', // Adjusted width to fit the container
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography component="p" variant="h4" sx={{ marginTop: 8 }}>
                      {user.not_approved} รายการ
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                      ไม่อนุมัติ
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper
                    elevation="4"
                    sx={{
                      p: 2,
                      height: 240,
                      width: '100%', // Adjusted width to fit the container
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography component="p" variant="h4" sx={{ marginTop: 8 }}>
                      {user.all_out_status} รายการ
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                      รวมทั้งหมด
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Stack>
          </Box>
          </>
        ) : (
          <>
            <p>ไม่พบโทเคน</p>
            <CircularProgress />
          </>
        )}
      </Container>
    </>
  );
};

export default HomeAdmin;
