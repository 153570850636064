// import React, { useState, useEffect } from "react";
// import { Container, Avatar } from "@mui/material";
// import axios from "axios";
// import { DataGrid } from '@mui/x-data-grid';

// import MenuAppBar from '../components/AppBar'; 
// import 'react-toastify/dist/ReactToastify.css';
// import { useNavigate } from 'react-router-dom';
// import {Button, Typography, Backdrop, CircularProgress} from '@mui/material';



// const Grade = () => {
//     const token = localStorage.getItem('token');
//     const navigate = useNavigate();
//     const [user, setUser] = useState({});
//   useEffect(() => {
//     fetch('http://localhost:8080/api/user', {
//       method: 'POST',
//       headers: {
//         'Authorization': `Bearer ${token}`
//       },
//     })
//       .then(response => response.json())
//       .then(data => {
//         if (data.status === 200) {
//          setUser(data.user);
//         } else {
//           localStorage.removeItem('token');
//           navigate('/app/login');
//         } 
//       })
//       .catch(error => {
//         localStorage.removeItem('token');
//           navigate('/app/login');
//           alert('คุณไม่ได้ทำรายการในเวลาที่กำหนด เพื่อความปลอดภัยในการทำรายการ กรุณาเข้าสู่ระบบใหม่อีกครั้ง');
//       });
//   }, []);



  

//   return (
//     <>
//     <MenuAppBar />
//     <Container component="main" maxWidth="lg" sx={{
//             marginTop: 10,
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             textAlign: 'center',
//           }}>
//       <h1>แจ้งเกรดของ :  {user.acc_firstname} {user.acc_surname}</h1>
//       <Typography color="text.secondary" sx={{ flex: 0.5}}>
//       ระบบจะเปิดให้ดูเกรดได้ในอีก 00 วัน 02 ชั่วโมง 11 นาที 07 วินาที
//       </Typography>
//       </Container>
//       {/* <Backdrop
//         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//         open={open}
//       >
//         <CircularProgress color="inherit" />
//       </Backdrop> */}
//     </>
//   );
// };

// export default Grade;

import React from 'react';
import { useNavigate } from 'react-router-dom'; // นำเข้า useNavigate แทน useHistory
import Button from '@mui/material/Button';

const Grade = () => {
  const navigate = useNavigate(); // เปลี่ยนการใช้ useHistory เป็น useNavigate

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '85vh', alignContent: 'center' }}>
      <div style={{ textAlign: 'center', alignItems: 'center', alignContent: 'center' }}>
        <h1>ขออภัยจากทีมผู้พัฒนา</h1>
        <p>เนื่องจากระบบเกรดยังมีปัญหาจึงยังไม่สามารถเปิดใช้งานได้ตอนนี้</p>
        <Button onClick={() => navigate(-1)} variant="text">ย้อนกลับ</Button> {/* ใช้ navigate(-1) เพื่อย้อนกลับไปหน้าก่อนหน้านี้ */}
      </div>
    </div>
  );
};

export default Grade;
