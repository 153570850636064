import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { Button, Modal } from "flowbite-react";
export default function FullScreenDialog() {
  const [open, setOpen] = useState(false);
  const [isAnnounce, setIsAnnounce] = useState({});

  useEffect(() => {
    const currentTime = new Date().getTime();
    const DialogexpirationTime = localStorage.getItem("DialogexpirationTime");

    if (currentTime > DialogexpirationTime) {
      setTimeout(() => {
        setOpen(true);
        sendAnnounceRequest();
      }, 2000);
    } else {
      setOpen(false);
    }
  }, []);

  const sendAnnounceRequest = async () => {
    try {
      setOpen(false);
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/announce`,
        {
          method: "POST",
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        setOpen(false);
      } else if (data.status === 201) {
        setOpen(true);
        setIsAnnounce(data);
      }
    } catch (error) {
      console.error("Error Announce:", error);
    }
  };

  const handleClose = () => {
    const currentTime = new Date().getTime();
    const DialognewExpirationTime = currentTime + 20 * 60 * 1000; // 20 minutes
    localStorage.setItem("isFirstDialog", "false");
    localStorage.setItem("DialogexpirationTime", DialognewExpirationTime);

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Modal
        dismissible
        show={open}
        className="z-[1000]"
        onClose={() => handleClose()}
      >
        <Modal.Header> ประกาศ MTwit Service's App</Modal.Header>
        <Modal.Body className="z-[1000] dark:bg-white bg-white">
          <div className="space-y-6 text-center">
            <img
              class="h-auto max-w-xs mx-auto rounded-lg"
              alt="isAnnounce.img"
              src={isAnnounce.img}
            />

            <p className="text-base leading-relaxed text-gray-500">
              {isAnnounce && isAnnounce.message ? (
                isAnnounce.message.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))
              ) : (
                <CircularProgress />
              )}
            </p>
            <p className="text-base leading-relaxed text-red-500">
              ระบบได้มีการเก็บ Log เพื่อทำตาม
              พระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ พ.ศ. 2550
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="w-full mt-3 items-center px-4  py-2 border border-gray-300 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-300"
            onClick={() => handleClose()}
          >
            ตกลง
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
