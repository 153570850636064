import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



const LineConnectApi = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const callbackUrl = queryParams.get('callbackUrl');


  useEffect(() => {
    setLoading(true);
    localStorage.setItem('token', id);
      const timer = setTimeout(() => {
        if(!callbackUrl){
          navigate('/app/home');
        } else {
          navigate(callbackUrl);
        }
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
  }, []);
  
  return (
    <div>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default LineConnectApi